import React, { Component } from 'react';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import { urlbaseApi } from '../api';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { apiDashboard, apiDashboardDowload } from '../api'
import {
    MDBIcon, MDBModal, MDBModalBody, MDBModalHeader,
    MDBModalFooter, MDBBtn
} from "mdbreact";
import { saveAs } from 'file-saver'

class ImageUploadProposta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            urlUpload: urlbaseApi + `/api/Dashboard/Corretores/uploadImageProposta?idproposta=${this.props.idproposta}&localidade=Cb`,
            files: [],
            modalDelete: false,
            idImagem: null,
            isUploading: false
        }
        this.buscaFilesByProposta = this.buscaFilesByProposta.bind(this)
        this.confirmarExcluir = this.confirmarExcluir.bind(this)
        this.afterSaveCell = this.afterSaveCell.bind(this)
    }

    handleUploadFile = (event) => {
        if (event.target.files[0] !== undefined) {
            const data = new FormData();
            for (let index = 0; index < event.target.files.length; index++) {
                data.append('file', event.target.files[index], event.target.files[index].name);
            }

            this.setState({ isUploading: true })
            apiDashboard.post(this.state.urlUpload, data)
                .then(res => {
                    this.setState({ isUploading: false })
                    this.buscaFilesByProposta();
                }).catch(erro => {
                    this.setState({ isUploading: false, error: true })
                })
                
        }
    }
    componentWillMount() {
        this.buscaFilesByProposta()
    }
    //formata date and time tabela
    dateFormat(cell, row) {   // JSX example
        var dt = new Date(cell)
        return (dt.toLocaleDateString())
    }
    toggleModal = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }
    cellButton(cell, row, enumObject, rowIndex) {
        return (<div>
            <button
                className="btn btn-info" placeholder="Download Arquivo"
                type="button"
                onClick={() =>
                    this.clickDowloadFile(cell, row, rowIndex)}>
                <MDBIcon icon="file-download" />
            </button>
            <button
                className="btn btn-danger"
                type="button"
                onClick={() =>
                    this.clickDeleteFile(cell, row, rowIndex)}>
                <MDBIcon icon="trash-alt" />
            </button>
        </div>
        )
    }

    clickDowloadFile(cell, row, rowIndex) {
        this.setState({ isUploading: true })
        apiDashboardDowload.get('Corretores/DownloadImagemByProposta?idImagem=' + row.Id)
            .then(response => {
                //  const tabelas = res.data;
                var fileStream = response.data;
                var blob = new Blob([fileStream], {
                    //type: "image/png"
                });
                this.setState({ isUploading: false })
                saveAs(blob, row.NomeOriginal)
            }).catch(erro => {
                console.log(erro);
                this.setState({ isUploading: false })
            })
    }
    clickDeleteFile(cell, row, rowIndex) {
        this.setState({ idImagem: row.Id })
        this.toggleModal()
    }
    confirmarExcluir() {
        this.setState({ isUploading: true })
        apiDashboard.post(`/Corretores/DeleteImagemAnexoProposta?idImagem=${this.state.idImagem}`, {})
            .then(response => {

                if (response.status === 200) {
                    this.toggleModal()
                    this.buscaFilesByProposta()
                }
                this.setState({ isUploading: false })
            })
            .catch(erro => {
                this.setState({ msgInfoApi: erro, modalInfo: true })
                this.setState({ isLoading: false })
            })
    }
    buscaFilesByProposta() {
        this.setState({ isLoading: true })
        apiDashboard.get(`/Corretores/BuscaImagensPorProposta?idProposta=${this.props.idproposta}`)
            .then(res => {
                const images = res.data;

                if (images !== null) {
                    this.setState({ files: images })
                } else {
                    this.setState({ files: [] })
                }
            }).catch(erro => {
                // this.setState({ isLoading: false })
            })
    }
    afterSaveCell(row, cellName, cellValue) {
        // You can do any validation on here for editing value,
        // return false for reject the editing
        this.setState({ isUploading: true })
        apiDashboard.post(`/Corretores/UpdateImagePasta?idImage=${row.Id}&Pasta=${row.Pasta}`, {})
            .then(response => {

                this.setState({ isUploading: false })
                if (response.status === 200) {
                    return true;
                } else {
                    return false;
                }

            })
            .catch(erro => {
                this.setState({ isUploading: false })
                return false;
            })
        return true;
    }
    render() {
        const fetchInfo = {
            dataTotalSize: this.state.files.length // or checkbox
        };
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,

            afterSaveCell: this.afterSaveCell,
        };
        return (
            <div>

                <input name='file' type='file' multiple formEncType='multipart/form-data'
                    onChange={this.handleUploadFile} ></input>

                < br />
                < hr />
                {this.state.isUploading && <img src={require('../ajax-loader.gif')} alt="loading"></img>}
                < br />
                <div className="col-sm-12">
                    <BootstrapTable data={this.state.files} striped hover pagination={true} cellEdit={cellEditProp} fetchInfo={fetchInfo} >
                        <TableHeaderColumn isKey dataField='Id' hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='Pasta' >Pasta</TableHeaderColumn>
                        <TableHeaderColumn editable={false} dataField='NomeOriginal'>Arquivo</TableHeaderColumn>
                        <TableHeaderColumn editable={false} dataField='Data' dataFormat={this.dateFormat}>Data de atualização</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='button' editable={false}
                            dataFormat={this.cellButton.bind(this)}
                        />
                    </BootstrapTable>
                </div>

                <MDBModal isOpen={this.state.modalDelete} toggle={this.toggleModal} centered>
                    <MDBModalHeader toggle={this.toggleModal}>Atenção</MDBModalHeader>
                    <MDBModalBody>
                        Deseja excluir a imagem da proposta?
                     </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="success" onClick={this.toggleModal}>Fechar</MDBBtn>
                        <MDBBtn color="danger" onClick={this.confirmarExcluir}>Excluir</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </div>

        );
    }
}
export default ImageUploadProposta