import React, { Component } from 'react'
import { apiIdentity } from '../api'
import LoadingOverlay from 'react-loading-overlay';
import {
    MDBBtn
} from "mdbreact";
class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isAuth: false,
            isLogging: false,
            error: false,
            errorEsqueceu: false,
            msgEsqueceu: ""
        }
        this.usuario = null
        this.senha = null

        this.autenticaUsuario = this.autenticaUsuario.bind(this)
        this.esqueceuSenha = this.esqueceuSenha.bind(this)
    }
    esqueceuSenha() {
        this.setState({ isLogging: true, error: false, errorEsqueceu: false, msgEsqueceu: "" })
        if (this.usuario.value === null || this.usuario.value === '') {
            this.setState({ isLogging: false, errorEsqueceu: true, msgEsqueceu: "Favor preencher o campo E-mail!" })
        } else {
            apiIdentity.get(`api/account/recoveryPassword?email=${this.usuario.value}`)
                .then(res => {
                    const data = res.data;
                    this.setState({ isLogging: false, errorEsqueceu: true, msgEsqueceu: data.mensagemRetorno })
                }).catch(erro => {
                    this.setState({ isLogging: false, errorEsqueceu: true, msgEsqueceu: "Erro ao enviar E-mail!" })
                })
        }
    }
    autenticaUsuario() {

        this.setState({ isLogging: true, errorEsqueceu: false, msgEsqueceu: "" })
        var data = `grant_type=password&username=${this.usuario.value}_Cbu&password=${this.senha.value}`;
        apiIdentity.post('token', data)
            .then(res => {
                const persons = res.data;
                let corretor = Boolean(persons.corretor == "False" ? 0 : 1)
                let gerente = Boolean(persons.gerenteCorretor == "False" ? 0 : 1)
                if (corretor || gerente) {
                    this.setState({ isAuth: true })
                    localStorage.setItem('corretorapp', JSON.stringify(persons));
                     this.props.history.push("/admin/mapas");
                } else {
                    this.setState({ isLogging: false, error: true })
                }
            }).catch(erro => {
                this.setState({ isLogging: false, error: true })
            })
    }

    render() {
        return (
            <div>
                <LoadingOverlay
                    active={this.state.isLogging}
                    spinner
                    text='Aguarde...' >
                    <div className="containerLogin">
                        <div className="col-md-4">
                            <h1 className="text-center login-title">
                                < br />< br />
                            </h1>
                            <div className="account-wall">
                                <div className="form-signin">
                                    <fieldset className="form-group">
                                        <input type="text" className="form-control" name="Usuario" ref={ref => this.usuario = ref} id="exampleInputEmail1" placeholder="Usuário" />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <input type="password" className="form-control" name="Senha" ref={ref => this.senha = ref} id="exampleInputPassword1" placeholder="Senha" />
                                    </fieldset>
                                    {this.state.error && <small id="emailHelp" className="form-text erroacesso">Usuário e/ou senha incorretos</small>}
                                    {this.state.errorEsqueceu && <small id="emailHelp" className="form-text erroacesso">{this.state.msgEsqueceu}</small>}
                                    <hr />
                                    <a className="lkesqueceu" href="#" onClick={this.esqueceuSenha}> Esqueceu a senha? </a>
                                    <br /><br />
                                    <MDBBtn
                                        color="light-blue"
                                        className="btn btn-lg btn-primary btn-block" onClick={this.autenticaUsuario}
                                        type="submit">
                                        Acessar
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}
export default Login