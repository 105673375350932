import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {
    MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader,
    MDBModalFooter, MDBIcon, MDBRow, MDBCol
} from "mdbreact";
import LoadingOverlay from 'react-loading-overlay';
import { dadosCorretor } from '../utilitarios'
import { apiDashboard, apiDashboardDowload } from '../api';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ImageUploadProposta from './ImageUploadProposta'
import { saveAs } from 'file-saver'

class Propostas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAuth: false,
            isLoading: false,
            modal: false,
            modalStatus: false,
            propostas: [],
            modalImage: false,
            codigoproposta: 0,
            modalConfirmaExcluir: false,
            modalInfo: false,
            msgInfoApi: "",
            alertMotivoExclusao: 'none',
        }
        this.mostraAjudaTipo = this.mostraAjudaTipo.bind(this)
        this.mostraAjudaStatus = this.mostraAjudaStatus.bind(this)
        this.buscaPropostaPorCorretor = this.buscaPropostaPorCorretor.bind(this)
        this.toggleModalImage = this.toggleModalImage.bind(this)
        this.toggleModalEdit = this.toggleModalEdit.bind(this)
        this.imprimirProposta = this.imprimirProposta.bind(this)
        this.confirmaExcluirProposta = this.confirmaExcluirProposta.bind(this)
        this.toogleConfirmaExcluir = this.toogleConfirmaExcluir.bind(this)
    }
    toggleModalImage(id) {
        this.setState({ codigoproposta: id })
        this.setState({ modalImage: !this.state.modalImage })
    }
    toogleConfirmaExcluir(id) {
        this.setState({ codigoproposta: id })
        this.setState({ modalConfirmaExcluir: !this.state.modalConfirmaExcluir })
    }
    //modal info
    toogleInfo = () => {
        this.setState({
            modalInfo: !this.state.modalInfo
        });
    }

    toggleModalEdit(row) {
        this.props.history.push({
            pathname: "/admin/editar-proposta", state:
            {
                tipo: 'edit',
                codigoproposta: row.Idproposta,
                idarea: row.CodArea
            }
        });
    }
    imprimirProposta(id) {
        this.setState({ isLoading: true })
        apiDashboardDowload.get(`Corretores/DownloadProposta?idProposta=${id}`)
            .then(function (response, status, xhr) {
                let myHeaders = response.headers;
                let contentDisposition = myHeaders["content-disposition"]
                var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                filename = filename.replace('"', '');
                filename = filename.replace('"', '');
                var fileStream = response.data;
                var blob = new Blob([fileStream], {
                    //type: "image/png"
                });
                saveAs(blob, filename)
                this.setState({ isLoading: false })

            }).catch(erro => {
                console.log(erro)
                this.setState({ isLoading: false })
            })
    }
    confirmaExcluirProposta() {
        this.setState({ isLoading: true })
        let corretor = dadosCorretor();
        // this.setState({ codigoproposta: id })
        this.setState({ alertMotivoExclusao: 'none' })
        if (this.motivoExclusao.value.trim().length === 0) {
            this.setState({ alertMotivoExclusao: 'block', isLoading: false })
            return;
        }
        let obj = {
            IdProposta: this.state.codigoproposta,
            Motivo: this.motivoExclusao.value,
            NomeCorretor: corretor.nome
        }
        apiDashboard.post('/Corretores/ExcluirProposta', obj)
            .then(response => {
                if (response.data.CodErro > 0) {
                    this.setState({ modalInfo: true, msgInfoApi: response.data.MensagemRetorno })
                } else {
                    let corretor = dadosCorretor();
                    if (corretor !== null) {
                        this.buscaPropostaPorCorretor(corretor)
                    }
                }
                this.setState({ modalConfirmaExcluir: !this.state.modalConfirmaExcluir })
                this.setState({ isLoading: false })
            })
            .catch(erro => {
                this.setState({ isLoading: false })
            })

    }
    componentWillMount() {
        let storage = localStorage.getItem('corretorapp');
     
        if (storage !== null) {
            this.setState({ isAuth: true })
        
            let corretor = dadosCorretor();
            if (corretor !== null) {
              
                this.buscaPropostaPorCorretor(corretor)
            }
        }
    }

    limparFiltros() {
        this.refs.loteamento.cleanFiltered();
        this.refs.id.cleanFiltered();
        this.refs.lote.cleanFiltered();
        this.refs.quadra.cleanFiltered();
        this.refs.dataproposta.cleanFiltered();
        this.refs.tipo.cleanFiltered();
        this.refs.status.cleanFiltered();
    }
    ///busca propostas por corretor
    buscaPropostaPorCorretor(corretor) {
        if (corretor.gerenteCorretor != undefined) {
            this.setState({ isLoading: true })
            let id = corretor.id;
         
            let gerente = Boolean(corretor.gerenteCorretor == "False" ? 0 : 1)
            if (gerente) {
                id = 0;
            }
          
            apiDashboard.get(`/Corretores/BuscaPropostaPorCorretor?idCorretor=${id}`)
                .then(response => {
                    this.setState({ propostas: response.data })
                    this.setState({ isLoading: false })
                })
                .catch(erro => {
                    this.setState({ isLoading: false })
                })
        }
    }
    novaProposta() {
        this.props.history.push("/admin/nova-proposta");
    }
    dateFormatter(cell, row) {
        let dt = new Date(cell);
        return `${('0' + dt.getDate()).slice(-2)}/${('0' + (dt.getMonth() + 1)).slice(-2)}/${dt.getFullYear()}`;
    }
    mostraAjudaTipo() {
        this.setState({ modal: true })
    }
    mostraAjudaStatus() {
        this.setState({ modalStatus: true })
    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggleStatus = () => {
        this.setState({
            modalStatus: !this.state.modalStatus
        });
    }
    render() {
        //verifica se está autenticado
        if (!this.state.isAuth) {
            this.props.history.push("/login");
            document.location.reload(true);
        }
        let corretor = dadosCorretor();
        const columns = [{
            dataField: 'Idproposta',
            text: 'ID',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
            sort: true,
            filter: textFilter({
                placeholder: 'ID'
            })
        }, {
            dataField: 'Loteamento',
            text: 'Loteamento',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
            sort: true,
            filter: textFilter({
                placeholder: 'Loteamento'
            })
        }, {
            dataField: 'Lote',
            text: 'Lote',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'headerGrid',
            sort: true,
            filter: textFilter({
                placeholder: 'Lote'
            })
        }, {
            dataField: 'Quadra',
            text: 'Quadra',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'headerGrid',
            sort: true,
            filter: textFilter({
                placeholder: 'Quadra'
            })
        }, {
            dataField: 'Tipo',
            text: 'Tipo',
            align: 'left',
            headerAlign: 'left',
            sort: true,
            filter: textFilter({
                placeholder: 'Tipo'
            }),
            headerClasses: 'linkAjuda',
            headerEvents: {
                onClick: () => this.mostraAjudaTipo()
            }
        }, {
            dataField: 'Status',
            text: 'Status',
            align: 'left',
            headerAlign: 'left',
            sort: true,
            headerClasses: 'linkAjuda',
            filter: textFilter({
                placeholder: 'Status'
            }),
            headerEvents: {
                onClick: () => this.mostraAjudaStatus()
            }
        }, {
            dataField: 'DataProposta',
            text: 'Data da Proposta',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
            sort: true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            },
            filter: textFilter({
                placeholder: 'Data da proposta'
            })
        }];

        let gerente = Boolean(corretor.gerenteCorretor == "False" ? 0 : 1)
        if (gerente) {
            columns.push({
                dataField: 'Corretor',
                text: 'Corretor',
                align: 'left',
                headerAlign: 'left',
                headerClasses: 'headerGrid',
                sort: true,
                filter: textFilter({
                    placeholder: 'Corretor'
                })
            })
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            clickToExpand: true
        };

        const expandRow = {

            showExpandColumn: true,
            renderer: row => (
                <div>
                    <p>
                        <button type="button" className="btn btn-success btn-md" onClick={this.toggleModalEdit.bind(this, row)} >
                            <MDBIcon far icon="edit" />Editar proposta</button>
                        <button type="button" className="btn btn-warning btn-md" onClick={this.imprimirProposta.bind(this, row.Idproposta)} >
                            <MDBIcon icon="print" /> Imprimir proposta</button>
                        <button type="button" className="btn btn-info btn-md" onClick={this.toggleModalImage.bind(this, row.Idproposta)}    >
                            <MDBIcon far icon="images" />Arquivos da proposta</button>
                        <button type="button" hidden={!gerente} className="btn btn-danger btn-md" onClick={this.toogleConfirmaExcluir.bind(this, row.Idproposta)}    >
                            <MDBIcon far icon="images" />Excluir Proposta</button>

                    </p>
                </div>
            )
        };

        return (
            <div>
                <MDBContainer fluid>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text='Aguarde...' >
                        <MDBRow>
                            <MDBCol >
                                <BootstrapTable keyField='Idproposta' data={this.state.propostas} columns={columns}
                                    pagination={paginationFactory()}
                                    selectRow={selectRow}
                                    expandRow={expandRow}
                                    filter={filterFactory()}
                                />

                            </MDBCol>
                        </MDBRow>
                        {/* <MDBRow>
                            <MDBCol>
                                <button type="button" className="btn btn-success btn-lg" 
                                onClick={this.novaProposta.bind(this)} >Nova proposta</button>
                            </MDBCol>
                        </MDBRow> */}

                        <MDBModal isOpen={this.state.modalInfo} toggle={this.toogleInfo} centered>
                            <MDBModalHeader toggle={this.toogleInfo}>Informação</MDBModalHeader>
                            <MDBModalBody>

                                <div
                                    dangerouslySetInnerHTML={{ __html: this.state.msgInfoApi }} />
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toogleInfo}>FECHAR</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalImage} toggle={this.toggleModalImage} size="lg" centered>
                            <MDBModalHeader toggle={this.toggleModalImage}>  Fotos e arquivos da proposta
                        </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <ImageUploadProposta idproposta={this.state.codigoproposta} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={() => this.toggleModalImage(0)}>Fechar</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalConfirmaExcluir} toggle={this.toogleConfirmaExcluir} centered>
                            <MDBModalHeader toggle={this.toogleConfirmaExcluir}><strong>Deseja realmente excluir a proposta?</strong></MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <div className="alert alert-danger" role="alert"
                                            style={{ display: this.state.alertMotivoExclusao }}>
                                            <span>Motivo da Exclusão obrigatório!</span>
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                className="form-control" placeholder="Motivo da exclusão"
                                                ref={(ref) => this.motivoExclusao = ref}
                                                name="observacao"
                                                rows="5"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toogleConfirmaExcluir}>FECHAR</MDBBtn>
                                <MDBBtn color="primary" onClick={this.confirmaExcluirProposta} >EXCLUIR PROPOSTA</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modal} className="text-left" toggle={this.toggle} size="md" side position="bottom-right">
                            <MDBModalHeader toggle={this.toggle}>Tipos de proposta</MDBModalHeader>
                            <MDBModalBody>
                                <p><strong>* Proposta padrão:</strong>&nbsp; Proposta padrão segue diretamente para a equipe de cadastro, aguardando somente a análise para ser efetivada no sistema.</p>
                                <p><strong>* Proposta diferenciada:</strong>&nbsp; Proposta diferenciada passa por uma análise do gerente de vendas, podendo ser aprovada ou rejeitada.</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toggle}>FECHAR</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                        <MDBModal isOpen={this.state.modalStatus} toggle={this.toggleStatus} size="md" className="text-left" side position="bottom-right">
                            <MDBModalHeader toggle={this.toggleStatus}>Status da proposta</MDBModalHeader>
                            <MDBModalBody>
                                <p><strong>* Em aprovação:</strong>&nbsp; Proposta aguardando a análise do gerente de vendas.</p>
                                <p><strong>* Em análise:</strong>&nbsp; Proposta aguardando o prosseguimento pela equipe de cadastro.</p>
                                <p><strong>* Cadastrada:</strong>&nbsp; Proposta ja está confirmada no sistema e venda foi concluída.</p>
                                <p><strong>* Rejeitada:</strong>&nbsp; Proposta foi reprovada pelo gerente de vendas ou pela equipe de cadastro.</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toggleStatus}>FECHAR</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </LoadingOverlay>
                </MDBContainer>
            </div >
        )
    }
}
export default Propostas