import React, { Component } from 'react'
import { apiDashboard, apiDashboardDowloadCuritiba, apiDashboardDowloadGuarapuava, apiDashboardDowloadCamboriu } from '../api'
import LoadingOverlay from 'react-loading-overlay';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { saveAs } from 'file-saver'
import ComboAreas from '../components/comboareas';
import { MDBContainer } from "mdbreact";

class Tabelas extends Component {

    arAreas = []
    tabelasArquivos = []
    constructor(props) {
        super(props)
        this.state = {
            isAuth: false,
            isLoading: false,
            areas: [],
            files: [],
            areaSelecionada: null
        }
    }
    cellButton(cell, row, enumObject, rowIndex) {
        return (
            <button
                className="btn btn-info"
                type="button"
                onClick={() =>
                    this.clickDowloadTabela(cell, row, rowIndex)}>
                Baixar tabela
            </button>
        )
    }
    //download do arquivo
    clickDowloadTabela(cell, row, rowIndex) {

        if (this.state.areaSelecionada.localidade == 'Cb') {
            apiDashboardDowloadCamboriu.get(`Corretores/DownloadTabelaAnexo?id=${row.Id}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        } else if (this.state.areaSelecionada.localidade == 'Cwb') {
            apiDashboardDowloadCuritiba.get(`Corretores/DownloadTabelaAnexo?id=${row.Id}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        }
        else if (this.state.areaSelecionada.localidade == 'Gua') {
            apiDashboardDowloadGuarapuava.get(`Corretores/DownloadTabelaAnexo?id=${row.Id}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        }
    }
    //formata date and time tabela
    dateFormat(cell, row) {   // JSX example
        var dt = new Date(cell)
        return (dt.toLocaleDateString())
    }

    // ao trocar seleção de area
    selecionaArea = (areaSelecionada) => {
        this.setState({ areaSelecionada });
        this.buscaTabelaArea(areaSelecionada)
    }

    //busca na API tabelas da area selecionada
    buscaTabelaArea(areaSelecionada) {
        this.setState({ isLoading: true, files: [] })
        this.tabelasArquivos = []
        apiDashboard.get('Corretores/BuscaTabelaPorArea?idArea=' + areaSelecionada.value + '&localidade=' + areaSelecionada.localidade)
            .then(res => {
                const tabelas = res.data;
                tabelas.forEach(element => {

                    this.tabelasArquivos.push({
                        DataUpload: element.DataUpload,
                        Nome: element.Nome,
                        Id: element.Id
                    })
                });
                this.setState({ files: this.tabelasArquivos })
                this.setState({ isLoading: false })
            }).catch(erro => {
                this.setState({ isLoading: false })
            })
    }

    componentWillMount() {
        let storage = localStorage.getItem('corretorapp');
        if (storage !== null) {
            this.setState({ isAuth: true })
        }
    }

    render() {

        //verifica autenticacao do usuario
        if (!this.state.isAuth) {
            this.props.history.push("/login");
            document.location.reload(true);
        }
        const { areaSelecionada } = this.state;
        const fetchInfo = {
            dataTotalSize: this.state.files.length // or checkbox
        };

        return (
            <div>
                <MDBContainer fluid>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text='Aguarde...' >
                        <div className="row">
                            <div className="col-sm-0 col-lg-3">
                            </div>
                            <div className="col-sm-12 col-lg-6 text-left topArea">
                                <ComboAreas handlerSelecionaArea={this.selecionaArea} value={areaSelecionada} />
                            </div>
                            <div className="col-sm-0 col-lg-3">
                            </div>
                        </div>< br />
                        <div className="row">
                            <div className="col-sm-0 col-lg-1">
                            </div>
                            <div className="col-sm-12 col-lg-10">
                                <BootstrapTable data={this.state.files}  striped hover pagination={true} fetchInfo={fetchInfo} >
                                    <TableHeaderColumn isKey dataField='Id' hidden>ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='Nome'>Tabela</TableHeaderColumn>
                                    <TableHeaderColumn dataField='DataUpload' dataFormat={this.dateFormat}>Data de atualização</TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField='button'
                                        dataFormat={this.cellButton.bind(this)}
                                    />
                                </BootstrapTable>
                            </div>
                            <div className="col-sm-0 col-lg-1">
                            </div>
                        </div>
                    </LoadingOverlay>
                </MDBContainer>
            </div>
        )
    }
}
export default Tabelas