import axios from 'axios'
import { clearStorage } from './utilitarios'
import {
    develop,
    authUrl,
    curitibaUrl,
    camboriuUrl,
    guarapuavaUrl,
    loteUrl
} from './config';

// export const urlHost = 'http://localhost:' + backendPortSufix + '11';
export const urlbaseApi = loteUrl
export const urlHostCuritiba = curitibaUrl;
export const urlHostCamboriu = camboriuUrl;
export const urlHostGuarapuava = guarapuavaUrl;
export const urlbaseIdentityApi = authUrl;

//#############

function tokenAcesso() {
    let token = JSON.parse(localStorage.getItem('corretorapp'))
    if (token !== null) {
        return token.access_token
    }
}
export const areasAcesso = function () {
    let token = JSON.parse(localStorage.getItem('corretorapp'))
    if (token !== null) {
        return JSON.parse(token.Areas || '[]')
    }
}
export const apiDashboardDowload = axios.create({
    baseURL: `${urlbaseApi}/api/Dashboard`,
    responseType: 'blob',
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
})

export const apiDashboardDowloadCamboriu = axios.create({
    baseURL: `${urlHostCamboriu}/api/Dashboard`,
    responseType: 'blob',
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
})
export const apiDashboardDowloadCuritiba = axios.create({
    baseURL: `${urlHostCuritiba}/api/Dashboard`,
    responseType: 'blob',
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
})

export const apiDashboardDowloadGuarapuava = axios.create({
    baseURL: `${urlHostGuarapuava}/api/Dashboard`,
    responseType: 'blob',
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
})

export const apiDashboardMapa = axios.create({
    baseURL: `${urlbaseApi}/api/Dashboard`,
    responseType: 'arraybuffer',
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
})

export const apiDashboard = axios.create({
    baseURL: `${urlbaseApi}/api/Dashboard`,
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() },
})

export const apiCorretoresLegacy = axios.create({
    baseURL: `${urlbaseApi}/api/Legacy/Corretores`,
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() },
})

apiDashboard.interceptors.response.use((response) => {
    return (response)
}, error => {
    console.log(error.response.status)
    if (error.response.status == "401") {
        clearStorage()
        window.location.pathname = './admin/login'
    }
    // return Promise.reject(error)
    if (error.response) {
        return (error.response.data)
    } else {
        return Promise.reject(error)
    }
})


export const apiLote = axios.create({
    baseURL: `${urlbaseApi}/api/Lote`,
    headers: { 'Authorization': 'Bearer :' + tokenAcesso() }
}).interceptors.response.use((response) => {
    return (response)
}, error => {
    console.log(error.response.status)
    if (error.response.status == "401") {
        clearStorage()
        window.location.pathname = './admin/login'
    }
    // return Promise.reject(error)
    if (error.response) {
        return (error.response.data)
    } else {
        return Promise.reject(error)
    }
})
export const apiViaCep = axios.create({
    baseURL: `https://viacep.com.br/ws/`
})
export const apiIdentity = axios.create({
    baseURL: `${urlbaseIdentityApi}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})
