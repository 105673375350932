import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Navegacao extends Component {

    nome = null
    constructor(props) {
        super(props)

        this.state = {
            isAuth: false,
            collapsed: true
        }
        this.sair = this.sair.bind(this)
        this.toggleNavbar = this.toggleNavbar.bind(this)
    }
    sair() {

        localStorage.removeItem('corretorapp');
        // document.location.reload(true);
       // this.props.history.push("/admin/login");
        console.log(this.props)
    }
    componentWillMount() {

    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        let storage = localStorage.getItem('corretorapp');
        const collapsed = this.state.collapsed;
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        let user = JSON.parse(storage)
        if (storage !== null) {
            //console.log(JSON.stringify( storage))

            this.nome = `Olá, ${user.Nome} ${user.gerenteCorretor === "True" ? '/ Gerente' : '/Corretor'}   `
        }
        if (storage !== null) {
            return <>
                <h2 className="dadosCorretor">&nbsp;&nbsp;{this.nome}</h2>
                <button className={`${classTwo}`} type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" onClick={this.toggleNavbar} aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${classOne}`} id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item"> <Link to='/admin/mapas'> &nbsp;&nbsp; MAPAS &nbsp;&nbsp; </Link> </li>
                        <li className="nav-item"> <Link to='/admin/propostas'> &nbsp;&nbsp; PROPOSTAS &nbsp;&nbsp;</Link> </li>
                        <li className="nav-item"> <Link to='/admin/tabelas'> &nbsp;&nbsp; TABELAS &nbsp;&nbsp; </Link> </li>

                        {user.gerenteCorretor === "True" ? <li className="nav-item"> <Link to='/admin/relatorios'> &nbsp;&nbsp; RELATÓRIOS &nbsp;&nbsp; </Link> </li> : null}
                        <li className="nav-item "> <Link onClick={this.sair} to='/admin/login'><span className="clsSair"> &nbsp;&nbsp; SAIR &nbsp;&nbsp;</span></Link> </li>
                    </ul>
                </div>
            </>
        } else {
            return <ul className="nav justify-content-end">
                <li className="nav-item"> <Link to='/admin/login'> &nbsp;&nbsp; LOGIN &nbsp;&nbsp; </Link> </li>
            </ul>
        }
    }
}
export default Navegacao


