import React, { Component } from 'react'
import {
    TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import {
    MDBContainer, MDBBtn
} from "mdbreact";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { dadosCorretor } from '../utilitarios'
import { apiDashboard,apiDashboardDowload } from '../api';
import LoadingOverlay from 'react-loading-overlay';
import { saveAs } from 'file-saver'

class Relatorios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: 1,
            reservas: [],
            dtIni: undefined,
            dtFim: undefined,
            isLoading: false
        }
        this.toogleTab = this.toogleTab.bind(this)
        this.handlerDate = this.handlerDate.bind(this)
        this.pesquisaReservas = this.pesquisaReservas.bind(this)
        this.imprimirReservas = this.imprimirReservas.bind(this)
        
    }
    componentDidMount() {
        var dateObj = new Date()
        var dateIni = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
        var dataFormat = `${dateObj.getUTCFullYear()}-${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}-${('0' + dateObj.getUTCDate()).slice(-2)}`
        var dataFormatIni = `${dateIni.getUTCFullYear()}-${('0' + (dateIni.getUTCMonth() + 1)).slice(-2)}-${('0' + dateIni.getUTCDate()).slice(-2)}`
        this.dtFim.value = dataFormat
        this.dtIni.value = dataFormatIni
        this.setState({ dtIni: this.dtIni.value })
        this.setState({ dtFim: this.dtFim.value })
    }
    toogleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    handlerDate = (event) => {

        if (event.target.name == "dtIni") {
            this.setState({ dtIni: event.target.value })
        } else if (event.target.name == "dtFim") {
            this.setState({ dtFim: event.target.value })
        }
    }
    pesquisaReservas = () => {

        this.setState({ reservas: [], isLoading: true })
        apiDashboard.get(`Corretores/BuscaReservaByDate?dtIni=${this.state.dtIni}&dtFim=${this.state.dtFim}`)
            .then(res => {
                if (res.data.length > 0) {
                    this.setState({ reservas: res.data })
                }
                this.setState({ isLoading: false })
            }).catch(erro => {
                console.log(erro)
                this.setState({ isLoading: false })
            })

    }
    imprimirReservas() {
        this.setState({ isLoading: true })
        apiDashboardDowload.get(`Corretores/ImprimeReservas?dtIni=${this.state.dtIni}&dtFim=${this.state.dtFim}`)
            .then(function (response, status, xhr) {
                let myHeaders = response.headers;
                let contentDisposition = myHeaders["content-disposition"]
                var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                filename = filename.replace('"', '');
                filename = filename.replace('"', '');
                var fileStream = response.data;
                var blob = new Blob([fileStream], {
                    //type: "image/png"
                });
                saveAs(blob, filename)
                this.setState({ isLoading: false })

            }).catch(erro => {
                console.log(erro)
                this.setState({ isLoading: false })
            })
    }
    render() {

        let corretor = dadosCorretor();
        let gerente = Boolean(corretor.gerenteCorretor == "False" ? 0 : 1)
        if (!gerente) {
            return <>  </>
        }
        const columns = [{
            dataField: 'Id',
            text: 'ID',
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'Loteamento',
            text: 'Loteamento',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'NomeCorretor',
            text: 'Corretor',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'Quadra',
            text: 'Quadra',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
            ,
        {
            dataField: 'Lote',
            text: 'Lote',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'Nome',
            text: 'Cliente',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'Celular',
            text: 'Celular',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        },
        {
            dataField: 'Email',
            text: 'E-mail',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
        }
            ,
        {
            dataField: 'DataCadastro',
            text: 'Data da reserva',
            align: 'left',
            headerAlign: 'left',
            sort: true,
            headerClasses: 'headerGrid',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            }
        },
        {
            dataField: 'Validade',
            text: 'Validade',
            align: 'left',
            headerAlign: 'left',
            headerClasses: 'headerGrid',
            sort: true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes().toString().length === 1 ? '0' + dateObj.getMinutes() : dateObj.getMinutes()}`;
            }
        }

        ];
        return (
            <>
                <MDBContainer fluid>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toogleTab('1'); }}
                            >
                                Reservas
                    </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toogleTab('2'); }}
                            >

                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col lg="12" sm="12">&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col sm="12" lg="3">
                                    <h4>Reservas período de:</h4>
                                </Col>
                                <Col sm="12" lg="3">
                                    <input type="date" onChange={this.handlerDate}
                                        className="form-control" ref={(ref) => this.dtIni = ref}
                                        name="dtIni" />
                                </Col>
                                <Col sm="12" lg="3">
                                    <input type="date" onChange={this.handlerDate}
                                        className="form-control" ref={(ref) => this.dtFim = ref}
                                        name="dtFim" />
                                </Col>
                                <Col sm="12" lg="3">
                                    <MDBBtn color="primary" onClick={this.pesquisaReservas}>Pesquisar</MDBBtn>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col >
                                    <LoadingOverlay
                                        active={this.state.isLoading}
                                        spinner
                                        text='Aguarde...' >
                                        <BootstrapTable keyField='Id' data={this.state.reservas}
                                            columns={columns}
                                            pagination={paginationFactory()}
                                            filter={filterFactory()}
                                        />
                                    </LoadingOverlay>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="0" lg="5" />
                                <Col lg="2">

                                    <button type="button" className="btn btn-success btn-lg"
                                  
                                        onClick={this.imprimirReservas} >IMPRIMIR</button>
                                </Col>
                                <Col sm="0" lg="5" />
                            </Row>
                        </TabPane>

                    </TabContent>
                </MDBContainer>
            </>
        )
    }
}
export default Relatorios