const develop = false;
const baseLimpa = false;

const portal_debug = false;
const portal_port = portal_debug ? '64811' : baseLimpa ? '7911' : '8911';

const authApi_debug = false;
const authApi_port = authApi_debug ? '648' : baseLimpa ? '79' : '89';

const filialApi_debug = false;
const filialApi_port = filialApi_debug ? '648' : baseLimpa ? '79' : '89';

const loteApi_debug = false;
const loteApi_port = loteApi_debug ? '648' : baseLimpa ? '79' : '89';

const portalUrl = develop ? 'http://localhost:' + portal_port + '/' : 'https://corretores.glaffitte.net.br/';
const authUrl = develop ? 'http://localhost:' + authApi_port + '01/' : 'https://identityglaffitte.azurewebsites.net/';
const curitibaUrl = develop ? 'http://localhost:' + filialApi_port + '02/' : 'https://apicuritiba.azurewebsites.net/';
const camboriuUrl = develop ? 'http://localhost:' + filialApi_port + '03/' : 'https://apicamboriu.azurewebsites.net/';
const guarapuavaUrl = develop ? 'http://localhost:' + filialApi_port + '04/' : 'https://apiguarapuava.azurewebsites.net/';
const loteUrl = develop ? 'http://localhost:' + loteApi_port + '05' : 'https://apilote.azurewebsites.net';

exports.develop = develop;
exports.portal_port = portal_port;
exports.portalUrl = portalUrl;
exports.authUrl = authUrl;
exports.curitibaUrl = curitibaUrl;
exports.camboriuUrl = camboriuUrl;
exports.guarapuavaUrl = guarapuavaUrl;
exports. loteUrl = loteUrl;