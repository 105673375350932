import React from 'react'
import { Link } from 'react-router-dom'
import Navegacao from './navegacao'
import {
    MDBIcon, MDBModal, MDBModalBody,MDBModalHeader
} from "mdbreact";
import { useState } from 'react';

const Header = props => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
        window.open("https://portal.pipefy.com/61fddc5a-2c85-452f-ae24-cec7eb847510");
        // setIsShowing(!isShowing);
      }

    return (
        <>
            <nav className='navbar navbar-expand-lg navbar-dark bg-dark static-top' >
                <div className='container'>
                    <Link className='navbar-brand' to='/admin/mapas'><img src='../Logo.png' alt="Logo" ></img>
                    </Link>
                    {/*
                    <button
                        className="btn btn-info" onClick={toggle} placeholder="Solicitação Sistemas"
                        type="button">
                        <MDBIcon icon="retweet" />
                    </button>
                    */}
                    <Navegacao />
                </div>
            </nav >
            <MDBModal isOpen={isShowing} toggle={toggle}   fullHeight position="left">
            <MDBModalHeader toggle={toggle}><strong>Suporte Sistemas</strong></MDBModalHeader>
                <MDBModalBody>
                    <iframe width="100%" height="580" src="https://app.pipefy.com/public_form/213520?embedded=true" 
                    frameBorder="0"></iframe>
                </MDBModalBody>

            </MDBModal>
        </ >
    )
}

export default Header