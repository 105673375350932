import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
} from "mdbreact";
import Select from "react-select";
import MaskedInput from "react-maskedinput";
import { apiViaCep, apiDashboard, apiCorretoresLegacy } from "../api";
import LoadingOverlay from "react-loading-overlay";
import CurrencyInput from "react-currency-input";
import { valida_cpf_cnpj, dadosCorretor } from "../utilitarios";
import { isDate } from "moment";
import * as EmailValidator from "email-validator";
import ComboAreas from "../components/comboareas";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

var buscaCidadeEstado = function (comp, callback) {
    apiDashboard
        .get("Administrativo/GetCidadeEstadoIbge")
        .then((res) => {
            var arUfs = [];
            res.data.forEach((element) => {
                arUfs.push({
                    value: element.Nome,
                    label: element.Nome,
                    cidades: element.ListaCidades,
                });
            });
            comp.setState({ estados: arUfs });
            callback(null, comp);
            //  this.setState({ isLoading: false, estados: arUfs })
        })
        .catch((erro) => {
            // this.setState({ isLoading: false })
            callback(erro, comp);
        });
};

class propostanova extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuth: false,
            modal: false,
            modalErros: false,
            msgValidade: "",
            modalConfirma: false,
            classValidade: "",
            modalInfo: false,
            isLoading: false,
            enabledSalvar: true,
            qtdeErros: 0,
            estadoCivilSelecionado: null,
            estadoCivilSocioSelecionado: null,
            formaPgtoTaxaCadastroSelecioanda: null,
            cidadeSelecionada: null,
            areaSelecionada: null,
            indFiscal: null,
            Codigo: null,
            Complemento: null,
            quadra: null,
            lote: null,
            msgInfoApi: null,
            areas: [],
            vlrVista: 0,
            vlrEntrada: 0,
            vlrRemanescente: 0,
            vlrPrestacao: 0,
            txCadastro: 0,
            validaCpf: false,
            codigoproposta: 0,
            estadosCivis: [
                { value: "1", label: "CASADO(A)" },
                { value: "2", label: "SOLTEIRO(A)" },
                { value: "3", label: "DIVORCIADO(A)" },
                { value: "4", label: "VIÚVO(A)" },
                { value: "5", label: "SEPARADO(A)" },
                { value: "7", label: "OUTROS" },
            ],
            formaPgtoTaxaCadastro: [
                { value: "1", label: "Á VISTA" },
                { value: "2", label: "CARTÃO" },
                { value: "3", label: "TRANSF/DEPÓSITO" },
                { value: "5", label: "SEM TAXA" },
                { value: "4", label: "OUTROS" },
            ],
            estados: [],
            cidades: [],
            idCompradorSelected: 0,

            ufSelecionada: null,
            cpfCnpjTitular: null,
            cpfConjuge: null,
            cpfCnpjSocio: null,
            cpfConjugeSocio: null,
            cep: null,
            entradas: [],
            compradores: [],
            alertTitular: "none",
            alertDtNasc: "none",
            messageDtNasc: "",
            alertCpf: "none",
            messageCpf: "",
            alertEstCivil: "none",
            alertCpfConjuge: "none",
            alertCpfSocio: "none",
            alertCpfConjugeSocio: "none",
            messageCpfConjuge: "",
            alertCep: "none",
            alertCelular: "none",
            alertEndereco: "none",
            alertNumero: "none",
            alertBairro: "none",
            alertUf: "none",
            alertCidade: "none",
            alertEmail: "none",
            alertEmail2: "none",
            alertVlrRemanescente: "none",
            alertQtdeParcelas: "none",
            alertVlrPrestacao: "none",
            alertPrimPrestacao: "none",
            alertArea: "none",
            alertQuadra: "none",
            alertLote: "none",
            alertTaxaJuros: "none",
            alertTaxaCadastro: "none",
            alertCorrecaoMonetaria: "none",

            alertVlrVista: "none",
            messageVlrVista: "",
            alertVlrEntrada: "none",
            messageVlrEntrada: "",
            alertEntradasNaoGeradas: "none",
            alertVendedor: "none",

            alertNomeComprador2: "none",
            alertDtNascComprador2: "none",
            alertCpfObrigatórioComprador2: "none",
            alertEstadoCivilComprador2: "none",
            messageEntradasNaoGeradas: "",
            msgEmail: "",
            tipoVendaAVista: false,
            tipoVendaAPrazo: true,
            selectedOptionParceriaCorretores: null,
            selectedOptionVendedor: null,
            corretoresList: [],
            visibleButtonUpdSocioComprador: true,
            modalVerificaCpf: false,
            msgVerificaCpf: "",
            cpfEncontrado: false,
            propostaEncontrada: null,
            gerente: false,
            disableVendedor: false,
        };
        this.endereco = null;
        this.bairro = null;
        this.complementoEndereco = null;
        this.propostaPadrao = true;

        this.buscaCep = this.buscaCep.bind(this);
        this.salvarProposta = this.salvarProposta.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.montaParcelaEntrada = this.montaParcelaEntrada.bind(this);
        this.confirmaProposta = this.confirmaProposta.bind(this);
        this.buscaQuadraLote = this.buscaQuadraLote.bind(this);
        this.editarProposta = this.editarProposta.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.buscaCorretores = this.buscaCorretores.bind(this);
        this.addComprador = this.addComprador.bind(this);
        this.updComprador = this.updComprador.bind(this);
        this.delComprador = this.delComprador.bind(this);
        this.cancelComprador = this.cancelComprador.bind(this);
        this.limpar = this.limpar.bind(this);
        this.selectComprador = this.selectComprador.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.verificaCpf = this.verificaCpf.bind(this);
        this.buscaCpfCadastrados = this.buscaCpfCadastrados.bind(this);
        this.confirmaDadosProposta = this.confirmaDadosProposta.bind(this);
        this.bindDadosCadastrais = this.bindDadosCadastrais.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.buscaCorretores();
        buscaCidadeEstado(this, function (err, comp) {
            let idArea = null;
            let localidade = null;
            if (comp.props.location.state != null) {
                let getstate = comp.props.location.state;
                comp.setState({
                    Codigo: getstate.Codigo,
                    Complemento: getstate.Complemento
                });
                if (getstate.tipo === "nova") {
                    comp.quadra.value = getstate.quadra;
                    comp.lote.value = getstate.lote;
                    comp.setState({ codigoproposta: 0 });
                    idArea = getstate.idarea;
                    localidade = getstate.localidade;
                } else if (getstate.tipo === "edit") {
                    comp.editarProposta(getstate.codigoproposta);
                    idArea = getstate.idarea;
                    comp.setState({ codigoproposta: getstate.codigoproposta });
                }
            }
            comp.populaArea(idArea, localidade);
        });
    }

    /**
     * preenche combo de estado
     */

    editarProposta(idProposta) {
        this.setState({ isLoading: true });
        apiDashboard
            .get(`Corretores/BuscaPropostaPorId?idProposta=${idProposta}`)
            .then((res) => {
                if (res.data !== null) {
                    this.bindProposta(res.data);
                }
            })
            .catch((erro) => {
                console.log(erro);
                this.setState({ isLoading: false });
            });
    }
    limpar() {
        this.setState({
            codigoproposta: 0,
            quadra: null,
            lote: null,
            areaSelecionada: null,
            indFiscal: null,
            Codigo: null,
            Complemento: null,
            estadoCivilSelecionado: null,
            estadoCivilSocioSelecionado: null,
            cidadeSelecionada: null,
            ufSelecionada: null,
            vlrVista: 0,

            vlrEntrada: 0,
            vlrRemanescente: 0,
            vlrPrestacao: 0,
            txCadastro: 0,
            formaPgtoTaxaCadastroSelecioanda: null,
            selectedOptionParceriaCorretores: [],
            entradas: [],
            compradores: [],
        });

        this.quadra.value = "";
        this.lote.value = "";
        this.area.value = "";
        this.matricula.value = "";
        this.propostaPadrao = true;
        this.titular.value = "";
        this.dtNasc.value = "";
        this.rg.value = "";
        this.cpfCnpjTitular.value = "";
        this.telefone.input.valu = "";
        this.endereco.value = "";
        this.bairro.value = "";
        this.qtdeEntrada.value = "";

        this.cepEdit = "";
        this.observacao.value = "";
        this.numero.value = "";
        this.email.value = "";
        this.telefone2.input.value = "";
        this.celular.input.value = "";

        this.emailSocio.value = "";
        this.celularEditSocio = "";

        this.telcomercial.input.value = "";
        this.email2.value = "";

        this.complementoEndereco.value = "";
        this.nomeConjuge.value = "";
        this.dtNascConjuge.value = "";
        this.rgConjuge.value = "";
        this.cpfConjuge.value = "";

        this.nomeSocio.value = "";
        this.dtNascSocio.value = "";
        this.rgSocio.value = "";
        this.cpfCnpjSocio.value = "";
        this.nomeConjugeSocio.value = "";
        this.dtNascConjugeSocio.value = "";
        this.rgConjujgeSocio.value = "";
        this.cpfConjugeSocio.value = "";
        this.profissaoConjugeComprador2.value = "";
        this.profissaoComprador2.value = "";

        this.qtdeparcelas.value = "";
        this.dtPrimPrestacao.value = "";
        this.txJuros.value = "";
        this.correcaoMonetaria.value = "";
        this.profissaoTitular.value = "";
        this.profissaoConjuge.value = "";

        this.celularEdit = "";
        this.celularEditSocio = "";
        this.telefoneEdit = "";
        this.telefone2Edit = "";
        this.telComercialEdit = "";
    }
    ///busca corretores
    buscaCorretores() {
        this.setState({ isLoading: true });
        let corretor = dadosCorretor();
        this.setState({
            gerente: corretor.gerenteCorretor === "True",
        });
        apiCorretoresLegacy
            .get(`/GetBasicSelectCorretorList`, {
                params: {
                    userId: corretor.id,
                },
            })
            .then((response) => {
                let corretoresAr = [];
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    corretoresAr.push({
                        value: element.IdCorretor,
                        label: element.Corretor,
                    });
                }
                this.setState({ corretoresList: corretoresAr });
                this.setState({ isLoading: false });
            })
            .catch((erro) => {
                this.setState({ isLoading: false });
            });
    }

    delComprador() {
        let found = this.state.compradores.find((comp) => comp.Id === this.state.idCompradorSelected);

        let arr = this.state.compradores;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === found) {
                arr.splice(i, 1);
                i--;
            }
        }
        //refaz o id
        for (var i = 0; i < arr.length; i++) {
            arr[i].Id = i + 1;
        }

        this.setState({
            visibleButtonUpdSocioComprador: true,
            idCompradorSelected: 0,
        });
        this.limparCamposCompradores();
    }
    updComprador() {
        let erro = false;
        this.setState({
            alertNomeComprador2: "none",
            alertDtNascComprador2: "none",
            alertCpfSocio: "none",
            alertCpfObrigatórioComprador2: "none",
            alertEstadoCivilComprador2: "none",
        });

        if (this.nomeSocio.value == "") {
            this.setState({ alertNomeComprador2: "block" });
            erro = true;
        }
        if (this.dtNascSocio.value == "") {
            this.setState({ alertDtNascComprador2: "block" });
            erro = true;
        }
        if (this.cpfCnpjSocio.value == "") {
            this.setState({ alertCpfObrigatórioComprador2: "block" });
            erro = true;
        }
        if (this.cpfCnpjSocio.value.trim().length !== 0) {
            if (!valida_cpf_cnpj(this.cpfCnpjSocio.value)) {
                this.setState({ alertCpfSocio: "block" });
                erro = true;
            }
        }
        if (this.state.estadoCivilSocioSelecionado == null) {
            this.setState({ alertEstadoCivilComprador2: "block" });
            erro = true;
        }

        if (erro) return;

        const newArrCompradores = this.state.compradores.map((found) => {
            if (found.Id == this.state.idCompradorSelected) {
                return {
                    ...found,
                    NomeSocioComprador: this.nomeSocio.value,
                    DtNascSocio: this.dtNascSocio.value,
                    RgSocio: this.rgSocio.value,
                    CpfSocio: this.cpfCnpjSocio.value,
                    EstadoCivilSocio: this.state.estadoCivilSocioSelecionado,
                    ProfissaoSocio: this.profissaoComprador2.value,

                    SocioConjuge: this.nomeConjugeSocio.value,
                    DtNascSocioConjuge: this.dtNascConjugeSocio.value,
                    RgSocioConjuge: this.rgConjujgeSocio.value,
                    CpfSocioConjuge: this.cpfConjugeSocio.value,
                    ProfissaoConjugeSocio: this.profissaoConjugeComprador2.value,
                    CelularSocio: this.celularSocio.input.value,
                    EmailSocio: this.emailSocio.value,
                };
            }
            return found;
        });

        this.limparCamposCompradores();
        this.setState({
            visibleButtonUpdSocioComprador: true,
            idCompradorSelected: 0,
            compradores: [],
        });
        this.setState({ compradores: newArrCompradores });
    }

    cancelComprador() {
        this.setState({
            visibleButtonUpdSocioComprador: true,
            idCompradorSelected: 0,
        });
        this.limparCamposCompradores();
    }

    verificaCpf() {
        this.setState({ validaCpf: false });
        if (this.cpfCnpjTitular.value.trim().length > 0) {
            if (valida_cpf_cnpj(this.cpfCnpjTitular.value)) {
                this.setState({ validaCpf: true });
            }
        }
    }

    buscaCpfCadastrados() {
        this.setState({
            isLoading: true,
            msgVerificaCpf: "",
            cpfEncontrado: false,
            modalVerificaCpf: false,
        });
        apiDashboard
            .get(`Corretores/BuscaCadastroByCpf?cpf=${this.cpfCnpjTitular.value}`)
            .then((res) => {
                if (res.data !== null) {
                    // this.bindProposta(res.data);
                    this.setState({
                        msgVerificaCpf: `Encontrado o cadastro de ${res.data.NomeTitular} para o CPF, deseja importar os dados?`,
                        cpfEncontrado: true,
                        modalVerificaCpf: true,
                        propostaEncontrada: res.data,
                    });
                } else {
                    this.setState({
                        msgVerificaCpf: `Nenhum cadastro encontrado!`,
                        modalVerificaCpf: true,
                    });
                }
                this.setState({ isLoading: false });
            })
            .catch((erro) => {
                console.log(erro);
                this.setState({ isLoading: false });
            });
    }

    confirmaDadosProposta() {
        this.setState({
            isLoading: true,
            msgVerificaCpf: "",
            cpfEncontrado: false,
            modalVerificaCpf: false,
        });

        this.bindDadosCadastrais(this.state.propostaEncontrada);
        this.setState({
            isLoading: false,
        });
    }

    addComprador() {
        let erro = false;
        this.setState({
            alertNomeComprador2: "none",
            alertDtNascComprador2: "none",
            alertCpfSocio: "none",
            alertCpfObrigatórioComprador2: "none",
            alertEstadoCivilComprador2: "none",
        });

        if (this.nomeSocio.value == "") {
            this.setState({ alertNomeComprador2: "block" });
            erro = true;
        }
        if (this.dtNascSocio.value == "") {
            this.setState({ alertDtNascComprador2: "block" });
            erro = true;
        }
        if (this.cpfCnpjSocio.value == "") {
            this.setState({ alertCpfObrigatórioComprador2: "block" });
            erro = true;
        }
        if (this.cpfCnpjSocio.value.trim().length !== 0) {
            if (!valida_cpf_cnpj(this.cpfCnpjSocio.value)) {
                this.setState({ alertCpfSocio: "block" });
                erro = true;
            }
        }
        if (this.state.estadoCivilSocioSelecionado == null) {
            this.setState({ alertEstadoCivilComprador2: "block" });
            erro = true;
        }

        if (erro) return;

        let objComprador = {
            Id: this.state.compradores.length + 1,
            NomeSocioComprador: this.nomeSocio.value,
            DtNascSocio: this.dtNascSocio.value,
            RgSocio: this.rgSocio.value,
            CpfSocio: this.cpfCnpjSocio.value,
            EstadoCivilSocio: this.state.estadoCivilSocioSelecionado,
            ProfissaoSocio: this.profissaoComprador2.value,

            SocioConjuge: this.nomeConjugeSocio.value,
            DtNascSocioConjuge: this.dtNascConjugeSocio.value,
            RgSocioConjuge: this.rgConjujgeSocio.value,
            CpfSocioConjuge: this.cpfConjugeSocio.value,
            ProfissaoConjugeSocio: this.profissaoConjugeComprador2.value,
            CelularSocio: this.celularSocio.input.value,
            EmailSocio: this.emailSocio.value,
        };
        let compradores = this.state.compradores;
        compradores.push(objComprador);

        this.setState({ compradores: compradores });
        this.limparCamposCompradores();
    }

    selectComprador(row) {
        this.nomeSocio.value = row.NomeSocioComprador;
        this.dtNascSocio.value = row.DtNascSocio;
        this.rgSocio.value = row.RgSocio;
        this.cpfCnpjSocio.value = row.CpfSocio;
        //this.state.estadoCivilSocioSelecionado = row.EstadoCivilSocio
        this.profissaoComprador2.value = row.ProfissaoSocio;

        let estadoCivil = this.state.estadosCivis.filter((r) => r.label === row.EstadoCivilSocio.label);
        if (estadoCivil.length > 0) {
            this.setState({ estadoCivilSocioSelecionado: estadoCivil[0] });
        }

        this.nomeConjugeSocio.value = row.SocioConjuge;
        this.dtNascConjugeSocio.value = row.DtNascSocioConjuge;
        this.rgConjujgeSocio.value = row.RgSocioConjuge;
        this.cpfConjugeSocio.value = row.CpfSocioConjuge;
        this.profissaoConjugeComprador2.value = row.ProfissaoConjugeSocio;

        this.celularEditSocio = row.CelularSocio;
        this.emailSocio.value = row.EmailSocio;

        this.setState({
            visibleButtonUpdSocioComprador: false,
            idCompradorSelected: row.Id,
        });
    }

    limparCamposCompradores() {
        this.nomeSocio.value = "";
        this.dtNascSocio.value = "";
        this.rgSocio.value = "";
        this.cpfCnpjSocio.value = "";
        this.nomeConjugeSocio.value = "";
        this.dtNascConjugeSocio.value = "";
        this.rgConjujgeSocio.value = "";
        this.cpfConjugeSocio.value = "";
        this.profissaoConjugeComprador2.value = "";
        this.profissaoComprador2.value = "";
        this.celularEditSocio = "";
        this.emailSocio.value = "";
        this.setState({ estadoCivilSocioSelecionado: null });
    }

    bindProposta(data) {
        try {
            this.setState({ entradas: [] });
            this.quadra.value = data.Quadra;
            this.lote.value = data.Lote;
            if (data.Validade != null) {
                var optionsWekk = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                };
                let validade = new Date(data.Validade);
                let now = new Date();
                let classvalidade = validade < now ? "classValidadeRed" : "classValidadeGreen";
                this.setState({
                    msgValidade: `Proposta aguardando documentação até: ${validade.toLocaleDateString(
                        "pt-BR",
                        optionsWekk
                    )} as ${validade.getHours().toString().padStart(2, "0")}:${validade
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`,
                    classValidade: classvalidade,
                });
            }
            if (this.state.corretoresList.length > 0) {
                let corretorFound = this.state.corretoresList.find((r) => r.value === data.IdCorretor);
                this.setState({ selectedOptionVendedor: corretorFound, disableVendedor: true });
            }

            this.area.value = data.MetroQuadrado;
            this.setState({ indFiscal: data.IndFiscal });
            this.matricula.value = data.Matricula;

            this.setState({ Codigo: data.Codigo });
            this.setState({ Complemento: data.Complemento });

            this.bindDadosCadastrais(data);
            this.observacao.value = data.Observacoes;

            /*
      this.nomeSocio.value = data.SocioComprador
      this.dtNascSocio.value = data.DtNascSocio
      this.rgSocio.value = data.RgSocio
      this.cpfCnpjSocio.value = data.CpfSocio
      this.nomeConjugeSocio.value = data.SocioConjuge
      this.dtNascConjugeSocio.value = data.DtNascSocioConjuge
      this.rgConjujgeSocio.value = data.RgSocioConjuge
      this.cpfConjugeSocio.value = data.CpfSocioConjuge
      this.profissaoConjugeComprador2.value = data.ProfissaoConjugeSocio
      this.profissaoComprador2.value = data.ProfissaoSocio
      let estadoCivilSocio = this.state.estadosCivis.filter(r => r.label === data.EstadoCivilSocio)
      if (estadoCivilSocio.length > 0) {
        this.setState({ estadoCivilSocioSelecionado: estadoCivilSocio[0] })
      }
      */

            if (data.Compradores != null && data.Compradores.length > 0) {
                this.setState({ compradores: data.Compradores });
            }
            this.setState({ vlrVista: this.returnDecimalFormat(data.VlrAVista) });
            this.setState({ vlrEntrada: this.returnDecimalFormat(data.VlrEntrada) });
            this.qtdeEntrada.value = data.QtdeEntradas;

            //VlrEntrada: vlrEntrada,
            //QtdeEntradas: qtdeEntrada,
            //ListaEntradas: entradas,
            this.statusProposta = data.StatusProposta;

            this.setState({
                vlrRemanescente: this.returnDecimalFormat(data.VlrRemanescente),
            });
            this.qtdeparcelas.value = data.QtdePrestacoes;
            this.setState({ vlrPrestacao: this.returnDecimalFormat(data.VlrPrest) });
            var dateObj = new Date(data.VenctoPrimeiraPrest);
            var dataFormat = `${dateObj.getUTCFullYear()}-${("0" + (dateObj.getUTCMonth() + 1)).slice(-2)}-${(
                "0" + dateObj.getUTCDate()
            ).slice(-2)}`;
            this.dtPrimPrestacao.value = dataFormat;
            let arrEntrada = [];
            let count = data.ListaEntradas !== null ? data.ListaEntradas.length : 0;
            for (let index = 0; index < count; index++) {
                arrEntrada.push({
                    id: index + 1,
                    valor: this.returnDecimalFormat(data.ListaEntradas[index].Valor),
                    datavencto: data.ListaEntradas[index].Data,
                });
            }
            let options = [];

            let countParceria = data.ParceriaCorretagem !== null ? data.ParceriaCorretagem.length : 0;
            for (let index = 0; index < countParceria; index++) {
                const element = data.ParceriaCorretagem[index];
                options.push({
                    value: element.IdCorretor,
                    label: element.Nome,
                });
            }
            // console.log(options)
            this.setState({ selectedOptionParceriaCorretores: options });
            this.setState({ entradas: arrEntrada });
            this.setState({ enabledSalvar: false });

            this.txJuros.value = data.TxJuros;
            this.setState({
                txCadastro: data.TaxaCadastro == null ? 0 : this.returnDecimalFormat(data.TaxaCadastro),
            });
            this.correcaoMonetaria.value = data.CorrecaoMonetaria;
            let formaPgto = this.state.formaPgtoTaxaCadastro.filter((r) => r.label === data.FormaPgtoTaxaCadastro);
            if (formaPgto.length > 0) {
                this.setState({ formaPgtoTaxaCadastroSelecioanda: formaPgto[0] });
            }

            this.setState({
                tipoVendaAVista: data.EntradaAVista == null ? true : data.EntradaAVista,
            });
            this.setState({ tipoVendaAPrazo: !this.state.tipoVendaAVista });
            this.propostaPadrao = data.PropostaPadrao;
        } catch (err) {
            console.log(err);
        }
    }

    bindDadosCadastrais(data) {
        this.titular.value = data.NomeTitular;
        this.dtNasc.value = data.DtNascimento;

        let estadoCivil = this.state.estadosCivis.filter((r) => r.label === data.EstadoCivil);
        if (estadoCivil.length > 0) {
            this.setState({ estadoCivilSelecionado: estadoCivil[0] });
        }

        this.rg.value = data.Rg;
        this.cpfCnpjTitular.value = data.Cpf;
        this.telefone.value = data.Fone;
        this.endereco.value = data.Endereco;
        this.bairro.value = data.Bairro;

        let uf = this.state.estados.filter((r) => r.label === data.Uf);

        if (uf.length > 0) {
            this.setState({ ufSelecionada: uf[0] });
            var obj = { ibge: data.Cidade };

            this.selecionaUf(uf[0], obj);
        }
        this.cepEdit = data.Cep;

        this.numero.value = data.Numero;
        this.email.value = data.Email;

        this.celularEdit = data.Celular;
        this.telefoneEdit = data.Fone;
        this.telComercialEdit = data.FoneComercial;
        this.telefone2Edit = data.Fone2;

        this.email2.value = data.Email2;
        this.complementoEndereco.value = data.ComptlEndereco;
        this.nomeConjuge.value = data.Conjuge;
        this.dtNascConjuge.value = data.DtNascConjuge;
        this.rgConjuge.value = data.RgConjuge;
        this.cpfConjuge.value = data.CpfConjuge;
        this.profissaoTitular.value = data.Profissao;
        this.profissaoConjuge.value = data.ProfissaoConjuge;
    }

    //** SALVAR PROPOSTA */
    salvarProposta() {
        this.validaProposta().then((qtdeEr) => {
            if (qtdeEr > 0) {
                this.setState({ modalErros: true, qtdeErros: qtdeEr });
            } else {
                //valida corretor
                let corretor = dadosCorretor();
                if (corretor !== null) {
                    this.setState({ modalConfirma: true });
                } else {
                    this.setState({
                        modalInfo: true,
                        msgInfoApi: " Corretor não encontrado, favor verificar! ",
                    });
                }
            }
        });
    }
    // Usuário confirma a proposta e salva
    confirmaProposta(e) {
        e.preventDefault();
        let corretor = dadosCorretor();
        if (this.state.gerente) {
            if (this.state.selectedOptionVendedor != null || this.state.selectedOptionVendedor != undefined) {
                corretor.nome = this.state.selectedOptionVendedor.label;
                corretor.id = this.state.selectedOptionVendedor.value;
            }
        }
        this.setState({ modalConfirma: false });
        let qtdeEntrada = 0;
        let vlrEntrada = 0;
        let entradas = [];
        let corretorParceiro = [];

        this.state.entradas.forEach((element) => {
            entradas.push({
                Valor: parseFloat(this.returnDecimalFormat(element.valor)),
                Data: element.datavencto,
            });
            vlrEntrada += parseFloat(this.returnDecimalFormat(element.valor));
            qtdeEntrada++;
        });
        if (this.state.selectedOptionParceriaCorretores != null) {
            this.state.selectedOptionParceriaCorretores.forEach((element) => {
                corretorParceiro.push({
                    IdCorretor: element.value,
                    Nome: element.label,
                });
            });
        }
        let objProposta = {
            IdProposta: this.state.codigoproposta,
            Codigo: this.state.Codigo,
            Complemento: this.state.Complemento,
            Quadra: this.quadra.value,
            Lote: this.lote.value,
            CodArea: this.state.areaSelecionada.value,
            NomeArea: this.state.areaSelecionada.label,
            Localidade: this.state.areaSelecionada.localidade,
            MetroQuadrado: this.area.value,
            IndFiscal: this.state.indFiscal,
            Matricula: this.matricula.value,

            NomeCorretor: corretor != null ? corretor.nome : "",
            IdCorretor: corretor != null ? corretor.id : "",

            PropostaPadrao: this.propostaPadrao,
            NomeTitular: this.titular.value,
            DtNascimento: this.dtNasc.value,
            Nacionalidade: "",

            EstadoCivil: this.state.estadoCivilSelecionado != null ? this.state.estadoCivilSelecionado.label : null,

            Rg: this.rg.value,
            Cpf: this.cpfCnpjTitular.value,
            Fone: this.telefone.input !== null ? this.telefone.input.value : null,
            Endereco: this.endereco.value,
            Bairro: this.bairro.value,
            Cidade: this.state.cidadeSelecionada !== null ? this.state.cidadeSelecionada.value : null,
            CidadeDescricao: this.state.cidadeSelecionada !== null ? this.state.cidadeSelecionada.label : null,
            Uf: this.state.ufSelecionada !== null ? this.state.ufSelecionada.value : null,
            Cep: this.cep.input !== null ? this.cep.input.value : null,
            Observacoes: this.observacao.value,
            Numero: this.numero.value,
            Email: this.email.value,
            Fone2: this.telefone2.input !== null ? this.telefone2.input.value : null,
            Celular: this.celular.input !== null ? this.celular.input.value : null,
            FoneComercial: this.telcomercial.input !== null ? this.telcomercial.input.value : null,
            Email2: this.email2.value,
            ComptlEndereco: this.complementoEndereco.value,
            Conjuge: this.nomeConjuge.value,
            DtNascConjuge: this.dtNascConjuge.value,
            RgConjuge: this.rgConjuge.value,
            CpfConjuge: this.cpfConjuge.value,

            Compradores: this.state.compradores,
            /* SocioComprador: this.nomeSocio.value,
       DtNascSocio: this.dtNascSocio.value,
       RgSocio: this.rgSocio.value,
       CpfSocio: this.cpfCnpjSocio.value,
       SocioConjuge: this.nomeConjugeSocio.value,
       DtNascSocioConjuge: this.dtNascConjugeSocio.value,
       RgSocioConjuge: this.rgConjujgeSocio.value,
       CpfSocioConjuge: this.cpfConjugeSocio.value,
       ProfissaoSocio: this.profissaoComprador2.value,
       ProfissaoConjugeSocio: this.profissaoConjugeComprador2.value,
        EstadoCivilSocio: this.state.estadoCivilSocioSelecionado != null ? this.state.estadoCivilSocioSelecionado.label : null,
       */

            VlrAVista: parseFloat(this.returnDecimalFormat(this.state.vlrVista)),
            VlrEntrada: vlrEntrada,
            QtdeEntradas: qtdeEntrada,
            ListaEntradas: entradas,

            VlrRemanescente: parseFloat(this.returnDecimalFormat(this.state.vlrRemanescente)),
            QtdePrestacoes: this.qtdeparcelas.value,
            VlrPrest: parseFloat(this.returnDecimalFormat(this.state.vlrPrestacao)),
            VenctoPrimeiraPrest: this.dtPrimPrestacao.value,

            StatusProposta: this.statusProposta,

            TxJuros: this.returnDecimalFormat(this.txJuros.value),
            TaxaCadastro: parseFloat(this.returnDecimalFormat(this.state.txCadastro)),

            CorrecaoMonetaria: this.correcaoMonetaria.value,

            Profissao: this.profissaoTitular.value,
            ProfissaoConjuge: this.profissaoConjuge.value,

            FormaPgtoTaxaCadastro:
                this.state.formaPgtoTaxaCadastroSelecioanda != null
                    ? this.state.formaPgtoTaxaCadastroSelecioanda.label
                    : "",
            EntradaAVista: this.state.tipoVendaAVista,
            ParceriaCorretagem: corretorParceiro,
        };

        //envia dados para api
        this.setState({ isLoading: true });

        apiDashboard
            .post("/Corretores/InserirOuAtualizarProposta", objProposta)
            .then((response) => {
                if (response.data.CodErro === 0) {
                    this.setState({
                        modalInfo: true,
                        msgInfoApi: response.data.MensagemRetorno,
                    });
                    setTimeout(() => {
                        this.props.history.push("/admin/propostas");
                    }, 2000);
                } else {
                    this.setState({
                        modalInfo: true,
                        msgInfoApi: response.data.MensagemRetorno,
                    });
                }

                this.setState({ isLoading: false });
            })
            .catch((erro) => {
                this.setState({ msgInfoApi: erro, modalInfo: true });
                this.setState({ isLoading: false });
            });
    }
    // ao trocar seleção de area
    selecionaArea = (areaSelecionada) => {
        this.setState({ areaSelecionada });
    };
    //faz o bind na api, populando os loteamentos
    async populaArea(idArea, localidade) {
        this.setState({ isLoading: true });
        let arAreas = [];
        let selectedAreaMapa = null;
        apiDashboard
            .get("Mapa/GetAreaMapaByLocalidade?localidade=")
            .then((res) => {
                const areas = res.data;
                areas.forEach((element) => {
                    if (element.IdArea === 0) {
                        return;
                    }

                    if (idArea !== null && element.IdArea === idArea) {
                        selectedAreaMapa = {
                            value: element.IdArea,
                            label: element.IdArea + " - " + element.NomeArea,
                            localidade: element.Localidade,
                        };
                    }
                    arAreas.push({
                        value: element.IdArea,
                        label: element.IdArea + " - " + element.NomeArea,
                        localidade: element.Localidade,
                    });
                });
                this.setState({ areas: arAreas });
                this.setState({ isLoading: false });
                if (selectedAreaMapa !== null) {
                    this.selecionaArea(selectedAreaMapa);
                }
            })
            .catch((erro) => {
                console.log(erro);
                this.setState({ isLoading: false });
            });
    }
    async validaProposta() {
        let countErro = 0;
        //valida titular
        this.setState({ alertTitular: "none" });
        if (this.titular.value === undefined || this.titular.value.trim().length === 0) {
            this.setState({ alertTitular: "block" });
            countErro++;
        }

        //Data de nascimento
        this.setState({ alertDtNasc: "none" });
        if (this.dtNasc.value.trim().length === 0) {
            this.setState({ alertDtNasc: "block" });
            this.setState({ messageDtNasc: "Data de nascimento obrigatória" });
            countErro++;
        }

        //cpf/cnpj titular
        if (this.cpfCnpjTitular.value.trim().length === 0) {
            this.setState({ alertCpf: "block" });
            this.setState({ messageCpf: "CPF/CNPJ obrigatório" });
            countErro++;
        } else {
            if (!valida_cpf_cnpj(this.cpfCnpjTitular.value)) {
                this.setState({ alertCpf: "block" });
                this.setState({ messageCpf: "CPF/CNPJ inválido" });
                countErro++;
            } else {
                this.setState({ alertCpf: "none" });
            }
        }
        //valida estado civil
        this.setState({ alertEstCivil: "none" });
        if (this.state.estadoCivilSelecionado === null) {
            this.setState({ alertEstCivil: "block" });
            countErro++;
        }
        //valida cpf conjuge
        this.setState({ alertCpfConjuge: "none" });
        if (this.cpfConjuge.value.trim().length !== 0) {
            if (!valida_cpf_cnpj(this.cpfConjuge.value)) {
                this.setState({ alertCpfConjuge: "block" });
                countErro++;
                this.setState({ messageCpfConjuge: "CPF/CNPJ do conjuge inválido" });
            }
        }
        //valida cep
        if (this.cep.input.value === undefined || this.cep.input.value.trim().length === 0) {
            this.setState({ alertCep: "block" });
            countErro++;
        } else {
            this.setState({ alertCep: "none" });
        }
        //valida endereco
        if (this.endereco.value.trim().length === 0) {
            this.setState({ alertEndereco: "block" });
            countErro++;
        } else {
            this.setState({ alertEndereco: "none" });
        }
        //valida numero
        if (this.numero.value.trim().length === 0) {
            this.setState({ alertNumero: "block" });
            countErro++;
        } else {
            this.setState({ alertNumero: "none" });
        }
        //valida bairro
        if (this.bairro.value.trim().length === 0) {
            this.setState({ alertBairro: "block" });
            countErro++;
        } else {
            this.setState({ alertBairro: "none" });
        }
        //valida UF
        this.setState({ alertUf: "none" });
        if (this.state.ufSelecionada === null) {
            this.setState({ alertUf: "block" });
            countErro++;
        }
        //valida cidade
        this.setState({ alertCidade: "none" });
        if (this.state.cidadeSelecionada === null) {
            this.setState({ alertCidade: "block" });
            countErro++;
        }
        //valida celular
        this.setState({ alertCelular: "none" });
        if (this.celular.input.value == null || this.celular.input.value.trim().length === 0) {
            this.setState({ alertCelular: "block" });
            countErro++;
        }
        //valida email 1
        this.setState({ alertEmail: "none" });
        if (this.email.value.trim().length > 0) {
            if (!this.IsEmail(this.email.value)) {
                this.setState({ alertEmail: "block", msgEmail: "E-mail inválido" });
                countErro++;
            }
        } else {
            this.setState({ alertEmail: "block", msgEmail: "E-mail obrigatório" });
            countErro++;
        }

        //valida email 2
        this.setState({ alertEmail2: "none" });
        if (this.email2.value.trim().length > 0) {
            if (!this.IsEmail(this.email2.value)) {
                this.setState({ alertEmail2: "block" });
                countErro++;
            }
        }
        //valida cpf socio
        this.setState({ alertCpfSocio: "none" });
        if (this.cpfCnpjSocio.value.trim().length !== 0) {
            if (!valida_cpf_cnpj(this.cpfCnpjSocio.value)) {
                this.setState({ alertCpfSocio: "block" });
                countErro++;
            }
        }
        //valida cpf cpf conjuge do socio
        this.setState({ alertCpfConjugeSocio: "none" });
        if (this.cpfConjugeSocio.value.trim().length !== 0) {
            if (!valida_cpf_cnpj(this.cpfConjugeSocio.value)) {
                this.setState({ alertCpfConjugeSocio: "block" });
                countErro++;
            }
        }
        this.setState({ alertVendedor: "none" });
        if (this.state.gerente) {
            if (this.state.selectedOptionVendedor == null || this.state.selectedOptionVendedor == undefined) {
                this.setState({ alertVendedor: "block" });
                countErro++;
            }
        }

        //valida valor a vista
        /* this.setState({ alertVlrVista: 'none', messageVlrVista: '' })
    
    
     if ( parseFloat(this.returnDecimalFormat(this.state.vlrVista)) === 0) {
       this.setState({ alertVlrVista: 'block', messageVlrVista: 'Valor á vista deve ser maior que R$ 0,00' })
       countErro++
     }*/

        //valida parcelas geradas
        this.setState({
            alertEntradasNaoGeradas: "none",
            messageEntradasNaoGeradas: "",
        });
        if (parseFloat(this.returnDecimalFormat(this.state.vlrEntrada)) > 0) {
            //valida se as entradas foram geradas
            if (this.state.entradas.length === 0) {
                this.setState({
                    alertEntradasNaoGeradas: "block",
                    messageEntradasNaoGeradas: "Parcelas de entradas devem ser corretamente cadastradas",
                });
                countErro++;
            }
        }
        //valida venda a vista
        this.setState({ alertVlrEntrada: "none", messageVlrEntrada: "" });
        if (this.state.tipoVendaAVista) {
            if (
                parseFloat(this.returnDecimalFormat(this.state.vlrEntrada)) !==
                parseFloat(this.returnDecimalFormat(this.state.vlrVista))
            ) {
                this.setState({
                    alertVlrEntrada: "block",
                    messageVlrEntrada: "Na venda á vista o valor da entrada deve ser igual ao valor á vista",
                });
                countErro++;
            }
        }

        //valida taxa de cadastro/
        this.setState({ alertTaxaCadastro: "none", messageTxCadastro: "" });
        if (this.state.txCadastro.length === 0) {
            this.setState({
                alertTaxaCadastro: "block",
                messageTxCadastro: "Taxa de cadastro obrigatória",
            });
            countErro++;
        }

        this.setState({ alertVlrRemanescente: "none", messageVlrRemanescente: "" });
        this.setState({ alertQtdeParcelas: "none" });
        this.setState({ alertVlrPrestacao: "none" });
        this.setState({ alertPrimPrestacao: "none" });
        this.setState({ alertTaxaJuros: "none", messageTaxaJuros: "" });
        this.setState({ alertCorrecaoMonetaria: "none" });
        if (this.state.tipoVendaAPrazo) {
            //somente valida se a venda for a prazo

            //valida taxa de juros
            this.setState({ alertTaxaJuros: "none", messageTaxaJuros: "" });
            if (
                this.txJuros.value.trim().length === 0 ||
                parseFloat(this.returnDecimalFormat(this.txJuros.value)) === 0
            ) {
                this.setState({
                    alertTaxaJuros: "block",
                    messageTaxaJuros: "Taxa de Juros deve ser maior que R$ 0,00",
                });
                countErro++;
            }
            //valida correcao monetaria
            if (this.correcaoMonetaria.value.trim().length === 0) {
                this.setState({
                    alertCorrecaoMonetaria: "block",
                    messageCorrecaoMonetaria: "Correção monetária obrigatória.",
                });
                countErro++;
            } else {
                this.setState({ alertCorrecaoMonetaria: "none" });
            }

            //valida valor remanescente
            this.setState({
                alertVlrRemanescente: "none",
                messageVlrRemanescente: "",
            });
            if (
                parseFloat(this.returnDecimalFormat(this.state.vlrRemanescente)) === 0 ||
                this.state.vlrRemanescente.trim().length == 0
            ) {
                this.setState({
                    alertVlrRemanescente: "block",
                    messageVlrRemanescente: "Valor remanescente é obrigatório",
                });
                countErro++;
            }
            // valida qtde de parcelas
            this.setState({ alertQtdeParcelas: "none" });
            if (
                parseFloat(this.returnDecimalFormat(this.qtdeparcelas.value)) === 0 ||
                this.qtdeparcelas.value.trim().length == 0
            ) {
                //valida se as entradas foram geradas
                this.setState({ alertQtdeParcelas: "block" });
                countErro++;
            }
            //valida valor da prestação
            this.setState({ alertVlrPrestacao: "none" });
            if (
                parseFloat(this.returnDecimalFormat(this.state.vlrPrestacao)) === 0 ||
                this.state.vlrPrestacao.trim().length == 0
            ) {
                //valida se as entradas foram geradas
                this.setState({ alertVlrPrestacao: "block" });
                countErro++;
            }
            // valida primeira prestacao
            this.setState({ alertPrimPrestacao: "none" });
            if (this.dtPrimPrestacao.value.trim().length === 0) {
                this.setState({ alertPrimPrestacao: "block" });
                countErro++;
            }
        }

        //valida area
        this.setState({ alertArea: "none" });
        if (this.state.areaSelecionada === null) {
            this.setState({ alertArea: "block" });
            countErro++;
        }
        //valida quadra
        this.setState({ alertQuadra: "none" });
        if (this.quadra.value.trim().length === 0) {
            this.setState({ alertQuadra: "block" });
            countErro++;
        }
        //valida lote
        this.setState({ alertLote: "none" });
        if (this.lote.value.trim().length === 0) {
            this.setState({ alertLote: "block" });
            countErro++;
        }

        return countErro;
    }
    replaceAllOccurrences(str, find, replace) {
        return str.replace(new RegExp(find, "g"), replace);
    }
    returnDecimalFormatTeste(num) {
        num = String(num);
        num = num.replace("R$", "");
        var indexThousands = num.indexOf(".");
        var indexDecimal = num.lastIndexOf(",");
        let strReturn = "";

        if (indexThousands > -1) {
            if (indexDecimal === -1) {
                strReturn = num.replace(".", ",");
            } else {
                strReturn =
                    num.substring(0, num.lastIndexOf(",")).split(".").join("") +
                    "." +
                    num.substring(num.lastIndexOf(",") + 1, num.lenght);
            }
        } else {
            strReturn = num.replace(",", ".");
        }

        return strReturn;
    }

    montaParcelaEntrada() {
        // console.log(parseFloat(this.returnDecimalFormatTeste(this.state.vlrEntrada)))

        if (parseFloat(this.returnDecimalFormat(this.state.vlrEntrada)) > 0) {
            if (parseFloat(this.returnDecimalFormat(this.qtdeEntrada.value)) > 0) {
                let arrEntrada = [];
                let dt = new Date();
                let vrlParcelaEntrada =
                    parseFloat(this.returnDecimalFormat(this.state.vlrEntrada)) /
                    parseFloat(this.returnDecimalFormat(this.qtdeEntrada.value));
                for (let index = 0; index < parseFloat(this.returnDecimalFormat(this.qtdeEntrada.value)); index++) {
                    arrEntrada.push({
                        id: index + 1,
                        valor: vrlParcelaEntrada.toFixed(2).split(".").join(","),
                        datavencto: this.add_months(dt, index).toDateString(),
                    });
                }
                this.setState({ entradas: arrEntrada });
            } else {
                this.setState({ entradas: [] });
            }
        } else {
            this.setState({ entradas: [] });
        }
    }
    /** Realiza a busca do Lote na Api e valida se o lote está vendido */
    buscaQuadraLote() {
        if (
            this.quadra.value.trim().length !== 0 &&
            this.lote.value.trim().length !== 0 &&
            this.state.areaSelecionada !== null
        ) {
            this.area.value = "";
            this.matricula.value = "";
            this.setState({ indFiscal: "" });
            this.setState({ enabledSalvar: true }); //desabilita o botao salvar

            //  TODO - DEV
            // console.log('buscaQuadraLote');
            // console.log(`Codigo: ${this.state.Codigo} - Complemento: ${this.state.Complemento}`);
            //  TODO - DEV

            //verifica se o lote existe
            apiDashboard
                .get(
                    `Corretores/BuscaImovel?localidade=${this.state.areaSelecionada.localidade}&codigo=${this.state.Codigo}&complemento=${this.state.Complemento}`
                )
                .then((res) => {
                    const result = res.data;
                    if (result.length > 0) {
                        this.area.value = result[0].Area !== null ? result[0].Area.toFixed(2) : "";
                        this.matricula.value = result[0].Matricula;
                        this.setState({
                            indFiscal: result[0].IndicacaoFiscal,
                            Codigo: result[0].Codigo,
                            Complemento: result[0].Complemento,
                        });
                        //verifica se há financiamento relacionado ao lote
                        apiDashboard
                            .get(
                                `Corretores/ValidaQuadraLote?localidade=${this.state.areaSelecionada.localidade}&codigo=${this.state.Codigo}&complemento=${this.state.Complemento}`
                            )
                            .then((resValida) => {
                                const resultV = resValida.data;
                                if (resultV.CodErro !== null && resultV.CodErro > 0) {
                                    this.setState({
                                        msgInfoApi: resultV.MensagemRetorno,
                                        modalInfo: true,
                                    });
                                } else {
                                    //se nao houver financiamento relacionado ao lote é liberado para cadastrar a proposta
                                    this.setState({ enabledSalvar: false });
                                }
                                this.setState({ isLoading: false });
                            })
                            .catch((erro) => {
                                console.log(erro);
                                this.setState({ isLoading: false });
                            });
                    } else {
                        this.setState({
                            enabledSalvar: true,
                            modalInfo: true,
                            msgInfoApi: "Lote não encontrado !!",
                        });
                    }
                    this.setState({ isLoading: false });
                })
                .catch((erro) => {
                    console.log(erro);
                    this.setState({ isLoading: false });
                });
        }
    }
    /**
     * Busca cep na api ViaCep ao usuario digitar
     */
    buscaCep() {
        this.setState({ isLoading: true });
        this.endereco.value = null;
        this.complementoEndereco.value = null;
        this.bairro.value = null;
        apiViaCep
            .get(this.removeSpecialCarac(this.cep.input.value) + "/json")
            .then((res) => {
                if (res.data.erro) {
                    this.setState({ modal: true });
                } else {
                    let resposta = res.data;
                    this.endereco.value = resposta.logradouro;
                    this.complementoEndereco.value = resposta.complemento;
                    this.bairro.value = resposta.bairro;
                    let estado = this.state.estados.find((f) => f.value === resposta.uf);
                    if (estado != null) {
                        this.selecionaUf(estado, resposta);
                    }
                }
                this.setState({ isLoading: false });
            })
            .catch((erro) => {
                this.setState({ modal: true });
                this.setState({ isLoading: false });
            });
    }

    /**Ao selecionar a UF, altera o estado */
    selecionaUf = (ufSelecionada, cidadeRetornaCep) => {
        this.setState({ cidadeSelecionada: null, ufSelecionada });
        var cidades = [];
        ufSelecionada.cidades.forEach((element) => {
            cidades.push({
                value: element.CodigoIbge,
                label: element.Nome,
            });
        });

        this.setState({ cidades: cidades });
        if (cidadeRetornaCep.ibge) {
            let cidadeCep = cidades.find((f) => f.value === cidadeRetornaCep.ibge);
            if (cidadeCep != null) {
                this.selecionaCidade(cidadeCep);
            }
        }
    };

    handleChangeVendedor = (selectedOptionVendedor) => {
        this.setState({ selectedOptionVendedor });
    };
    handleChangeParceriaCorretores = (selectedOptionParceriaCorretores) => {
        this.setState({ selectedOptionParceriaCorretores });
    };
    /**Ao selecionar a cidade, altera o estado */
    handleChangeRadio = (event) => {
        if (event.target.name == "tipoVendaAPrazo") {
            this.setState({
                tipoVendaAPrazo: event.target.checked,
                tipoVendaAVista: !event.target.checked,
            });
        } else {
            this.setState({
                tipoVendaAVista: event.target.checked,
                tipoVendaAPrazo: !event.target.checked,
            });
        }
    };
    /**Ao selecionar a cidade, altera o estado */
    selecionaCidade = (cidadeSelecionada) => {
        this.setState({ cidadeSelecionada });
    };
    /**acionado ao trocar o estado civil */
    alteraraEstadoCivil = (estadoCivilSelecionado) => {
        this.setState({ estadoCivilSelecionado });
    };
    /**acionado ao trocar o estado civil do socio */
    alteraraEstadoCivilSocio = (estadoCivilSocioSelecionado) => {
        this.setState({ estadoCivilSocioSelecionado });
    };
    /**acionado ao trocar o forma pgto taxa de cadastro */
    alteraFormaPgtoTaxaCadastro = (formaPgtoTaxaCadastroSelecioanda) => {
        this.setState({ formaPgtoTaxaCadastroSelecioanda });
    };
    //modal cep
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };
    //modal erros
    toggleErros = () => {
        this.setState({
            modalErros: !this.state.modalErros,
        });
    };
    //modal erros
    toogleSalvar = () => {
        this.setState({
            modalConfirma: !this.state.modalConfirma,
        });
    };
    //modal info
    toogleInfo = () => {
        this.setState({
            modalInfo: !this.state.modalInfo,
        });
    };
    toogleVerificaCpf = () => {
        this.setState({
            modalVerificaCpf: !this.state.modalVerificaCpf,
        });
    };
    // muda de estado do input e seta estado
    handleChange(e, maskedvalue, floatvalue) {
        //let name = event.target.name
        // this.setState({ name: maskedvalue });
        this.setState({ [e.target.name]: e.target.value });
    }
    /**acionado ao  */
    alteraPropostaPadrao = (propostaPadraoSelected) => {
        this.propostaPadrao = propostaPadraoSelected.target.checked;
    };
    //mascara de cpf cnpj
    _onMaskCpfCnpj = (e) => {
        if (!this.isNumber(e.target.value)) {
            e.preventDefault();
        }
        e.target.value = this.cpfCnpj(e.target.value);
        // this.setState({ [e.target.name]: (e.target.value) })
    };
    _onMaskDateBirth = (e) => {
        if (!this.isNumber(e.target.value)) {
            e.preventDefault();
        }

        e.target.value = this.dateBirth_formator(e.target.value);
    };
    IsEmail(email) {
        return EmailValidator.validate(email);
    }
    //verifica se é numerico
    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    add_months(dt, n) {
        return new Date(new Date(dt).setMonth(dt.getMonth() + n));
    }
    returnDecimalFormat(num) {
        num = String(num);
        num = num.replace("R$", "");
        var indexThousands = num.indexOf(".");
        var indexDecimal = num.lastIndexOf(",");
        let strReturn = "";

        if (indexThousands > -1) {
            if (indexDecimal === -1) {
                strReturn = num.replace(".", ",");
            } else {
                strReturn =
                    num.substring(0, num.lastIndexOf(",")).split(".").join("") +
                    "." +
                    num.substring(num.lastIndexOf(",") + 1, num.lenght);
            }
        } else {
            strReturn = num.replace(",", ".");
        }

        return strReturn;
    }
    removeSpecialCarac(str) {
        var outString = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, "");
        return outString;
    }

    //mascar de CPF e CNPJ
    cpfCnpj(v) {
        //Remove tudo o que não é dígito
        v = v.replace(/\D/g, "");
        if (v.length < 14) {
            //CPF
            //Coloca um ponto entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        } else {
            //CNPJ
            //Coloca ponto entre o segundo e o terceiro dígitos
            v = v.replace(/^(\d{2})(\d)/, "$1.$2");
            //Coloca ponto entre o quinto e o sexto dígitos
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
            //Coloca uma barra entre o oitavo e o nono dígitos
            v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
            //Coloca um hífen depois do bloco de quatro dígitos
            v = v.replace(/(\d{4})(\d)/, "$1-$2");
        }
        return v;
    }
    dateBirth_formator(v) {
        v = v.replace(/\D/g, "");
        if (v.length < 9) {
            //date birth
            //Coloca um ponto entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{2})(\d)/, "$1/$2");
            //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            v = v.replace(/(\d{2})(\d)/, "$1/$2");
            //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{4})(\d{1,3})$/, "$1/$2");
        } else {
        }
        return v;
    }
    /* TABLE EVENTS AND PROPERTIES*/
    priceFormatter(cell, row) {
        var formatado = "R$ " + parseFloat(cell).toFixed(2).replace(".", ",");
        return formatado;
    }
    /**Foramata data */
    dateFormatter(cell, row) {
        var date = new Date(cell.toString());
        if (!isDate(row.data)) {
            var ano = row.data.toString().substring(0, 4);
            var mes = row.data.toString().substring(5, 7);
            var dia = row.data.toString().substring(8, 10);
            date = new Date(ano, mes - 1, dia);
        }
        return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
    }
    isNumeric(value) {
        return /^\d+(?:\,\d+)?$/.test(value);
    }
    onRowSelect(row, isSelected) {
        this.selectComprador(row);
    }
    render() {
        const { estadoCivilSelecionado, estadoCivilSocioSelecionado, formaPgtoTaxaCadastroSelecioanda } = this.state;

        const { ufSelecionada } = this.state;
        const { cidadeSelecionada } = this.state;
        const { areaSelecionada } = this.state;
        const { selectedOptionParceriaCorretores } = this.state;
        const { selectedOptionVendedor } = this.state;
        //ao selecionar comprador no grid

        const columns = [
            {
                dataField: "id",
                text: "#",
            },
            {
                dataField: "valor",
                text: "Valor",
                //formatter: cell => `R$ ${this.returnDecimalFormat(cell)}`,
                type: "string",
                validator: (newValue, row, column, done) => {
                    if (!this.isNumeric(newValue)) {
                        return {
                            valid: false,
                            message: "POR FAVOR DIGITE SOMENTE NÚMEROS",
                        };
                    }
                },
            },

            {
                dataField: "datavencto",
                text: "Data de vencimento",
                formatter: (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== "object") {
                        dateObj = new Date(cell);
                    }
                    return `${("0" + dateObj.getUTCDate()).slice(-2)}/${("0" + (dateObj.getUTCMonth() + 1)).slice(
                        -2
                    )}/${dateObj.getUTCFullYear()}`;
                },
                editor: {
                    type: Type.DATE,
                },
            },
        ];

        const selectRowProp = {
            mode: "radio",
            clickToSelect: true,
            onSelect: this.onRowSelect,
        };

        const columnsCompradores = [
            {
                dataField: "Id",
                text: "#",
            },
            {
                dataField: "NomeSocioComprador",
                text: "Comprador",
                type: "string",
            },
            {
                dataField: "CpfSocio",
                text: "CPF/CNPJ",
                type: "string",
            },
            {
                dataField: "DtNascSocio",
                text: "Data de Nascimento",
                type: "string",
            },
            {
                dataField: "SocioConjuge",
                text: "Conjuge",
                type: "string",
            },
        ];

        return (
            <div>
                <LoadingOverlay active={this.state.isLoading} spinner text="Aguarde...">
                    <MDBContainer className="text-left">
                        <MDBCard style={{ marginTop: "1rem" }}>
                            <MDBCardHeader className="cardHeaderColor">
                                Dados Cadastrais -{" "}
                                <span className={this.state.classValidade}> {this.state.msgValidade}</span>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <label htmlFor="loteamento">
                                                <b>Loteamento</b>
                                            </label>
                                            <ComboAreas
                                                handlerSelecionaArea={this.selecionaArea}
                                                value={areaSelecionada}
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertArea }}
                                        >
                                            <span>Selecione o Loteamento.</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="form-group">
                                            <label htmlFor="quadra">
                                                <b>Quadra</b>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="quadra"
                                                ref={(ref) => (this.quadra = ref)}
                                                onChange={this.handleChange}
                                                id="formGroupExampleInput"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            onBlur={this.buscaQuadraLote}
                                            style={{ display: this.state.alertQuadra }}
                                        >
                                            <span>Quadra é obrigatória.</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="form-group">
                                            <label htmlFor="lote">
                                                {" "}
                                                <b>Lote</b>
                                            </label>
                                            <input
                                                type="text"
                                                name="lote"
                                                className="form-control"
                                                ref={(ref) => (this.lote = ref)}
                                                onBlur={this.buscaQuadraLote}
                                                onChange={this.handleChange}
                                                id="lote"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertLote }}
                                        >
                                            <span>Lote é obrigatório.</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="form-group">
                                            <label htmlFor="metros">
                                                {" "}
                                                <b>Área&nbsp;M²</b>
                                            </label>
                                            <input
                                                type="text"
                                                disabled
                                                ref={(ref) => (this.area = ref)}
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="metros"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="form-group">
                                            <label htmlFor="matricula">
                                                <b>Matrícula</b>
                                            </label>
                                            <input
                                                type="text"
                                                disabled
                                                ref={(ref) => (this.matricula = ref)}
                                                onChange={this.handleChange}
                                                className="form-control"
                                                id="matricula"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" hidden={!this.state.gerente}>
                                        <div className="form-group">
                                            <Select
                                                value={selectedOptionVendedor}
                                                onChange={this.handleChangeVendedor}
                                                isMulti={false}
                                                isDisabled={this.state.disableVendedor}
                                                placeholder="Vendedor"
                                                options={this.state.corretoresList}
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertVendedor }}
                                        >
                                            <span>Vendedor obrigatório.</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md={this.state.gerente ? "6" : "12"}>
                                        <div className="form-group">
                                            <Select
                                                value={selectedOptionParceriaCorretores}
                                                onChange={this.handleChangeParceriaCorretores}
                                                isMulti={true}
                                                placeholder="Proposta em parceria com..."
                                                options={this.state.corretoresList}
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <MDBRow>
                                    <MDBCol md="8">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                className="form-control"
                                                placeholder="Titular"
                                                ref={(ref) => (this.titular = ref)}
                                                name="titular"
                                                id="nome"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertTitular }}
                                        >
                                            <span>Nome do titular é obrigatório.</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Dt Nasc.
                                                    </span>
                                                </div>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Data de nascimento"
                                                    ref={(ref) => (this.dtNasc = ref)}
                                                    onChange={this._onMaskDateBirth}
                                                    type="tel"
                                                    name="dtNasc"
                                                    maxLength="10"
                                                    aria-label="Data de nascimento"
                                                    aria-describedby="basic-addon"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertDtNasc }}
                                        >
                                            {this.state.messageDtNasc}
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="RG"
                                                ref={(ref) => (this.rg = ref)}
                                                maxLength="50"
                                                name="rg"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                placeholder="CPF/CNPJ"
                                                ref={(ref) => (this.cpfCnpjTitular = ref)}
                                                onChange={this._onMaskCpfCnpj}
                                                onBlur={this.verificaCpf}
                                                maxLength="18"
                                                type="tel"
                                                className="form-control"
                                                name="cpfCnpjTitular"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCpf }}
                                        >
                                            {this.state.messageCpf}
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <button
                                            type="button"
                                            className="btn btn-warning btn-sm"
                                            onClick={this.buscaCpfCadastrados}
                                            hidden={!this.state.validaCpf}
                                        >
                                            Verificar CPF
                                        </button>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <input
                                            type="text"
                                            maxLength="200"
                                            placeholder="Profissão"
                                            ref={(ref) => (this.profissaoTitular = ref)}
                                            name="profissaoTitular"
                                            className="form-control"
                                            id="profissaoTitular"
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <Select
                                                placeholder="Estado Civil"
                                                options={this.state.estadosCivis}
                                                onChange={this.alteraraEstadoCivil}
                                                value={estadoCivilSelecionado}
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertEstCivil }}
                                        >
                                            <span>Estado civil obrigatório.</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>{" "}
                                <br></br>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                placeholder="Cônjuge"
                                                ref={(ref) => (this.nomeConjuge = ref)}
                                                name="nomeConjuge"
                                                className="form-control"
                                                id="nomeConjuge"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Dt Nasc.
                                                    </span>
                                                </div>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    ref={(ref) => (this.dtNascConjuge = ref)}
                                                    name="dtNascConjuge"
                                                    onChange={this._onMaskDateBirth}
                                                    maxLength="10"
                                                    placeholder="Data de nascimento"
                                                    aria-label="Data de nascimento"
                                                    aria-describedby="basic-addon"
                                                />
                                            </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="RG Conjuge"
                                                className="form-control"
                                                ref={(ref) => (this.rgConjuge = ref)}
                                                maxLength="50"
                                                name="rgConjuge"
                                                id="rgconjuge"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="CPF Conjuge"
                                                ref={(ref) => (this.cpfConjuge = ref)}
                                                onChange={this._onMaskCpfCnpj}
                                                maxLength="14"
                                                name="cpfConjuge"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCpfConjuge }}
                                        >
                                            <span>{this.state.messageCpfConjuge}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch2"
                                                defaultChecked={this.propostaPadrao}
                                                onChange={this.alteraPropostaPadrao}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch2">
                                                PROPOSTA PADRÃO
                                            </label>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <input
                                            type="text"
                                            maxLength="200"
                                            placeholder="Profissão"
                                            ref={(ref) => (this.profissaoConjuge = ref)}
                                            name="profissaoConjuge"
                                            className="form-control"
                                            id="profissaoConjuge"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <MDBRow>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <MaskedInput
                                                mask="11111-111"
                                                placeholder="CEP"
                                                onBlur={this.buscaCep}
                                                value={this.cepEdit}
                                                ref={(ref) => (this.cep = ref)}
                                                className="form-control"
                                                name="cep"
                                                type="tel"
                                                id="cep"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCep }}
                                        >
                                            <span>CEP obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                ref={(ref) => (this.endereco = ref)}
                                                placeholder="Endereço"
                                                className="form-control"
                                                name="endereco"
                                                id="endereco"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertEndereco }}
                                        >
                                            <span>Endereço é obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                placeholder="Número"
                                                ref={(ref) => (this.numero = ref)}
                                                className="form-control"
                                                name="numero"
                                                id="numero"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertNumero }}
                                        >
                                            <span>Número é obrigatório</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="100"
                                                placeholder="Bairro"
                                                ref={(ref) => (this.bairro = ref)}
                                                className="form-control"
                                                name="bairro"
                                                id="bairro"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertBairro }}
                                        >
                                            <span>Bairro é obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                placeholder="Complemento"
                                                ref={(ref) => (this.complementoEndereco = ref)}
                                                className="form-control"
                                                name="complementoEndereco"
                                                id="complementoEndereco"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <Select
                                                options={this.state.estados}
                                                value={ufSelecionada}
                                                onChange={this.selecionaUf}
                                                placeholder="UF"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertUf }}
                                        >
                                            <span>UF é obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <Select
                                                options={this.state.cidades}
                                                value={cidadeSelecionada}
                                                onChange={this.selecionaCidade}
                                                placeholder="Cidade"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCidade }}
                                        >
                                            <span>Cidade é obrigatória</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <MaskedInput
                                                placeholder="Celular"
                                                type="tel"
                                                className="form-control"
                                                value={this.celularEdit}
                                                mask="(11) 11111 1111"
                                                ref={(ref) => (this.celular = ref)}
                                                name="celular"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCelular }}
                                        >
                                            <span>Celular é obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <MaskedInput
                                                placeholder="Telefone"
                                                type="tel"
                                                className="form-control"
                                                value={this.telefoneEdit}
                                                mask="(11) 11111 1111"
                                                ref={(ref) => (this.telefone = ref)}
                                                name="telefone"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <MaskedInput
                                                placeholder="Telefone 2"
                                                value={this.telefone2Edit}
                                                type="tel"
                                                className="form-control"
                                                ref={(ref) => (this.telefone2 = ref)}
                                                mask="(11) 11111 1111"
                                                name="telefone2"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="3">
                                        <div className="form-group">
                                            <MaskedInput
                                                placeholder="Tel. Comercial"
                                                ref={(ref) => (this.telcomercial = ref)}
                                                className="form-control"
                                                value={this.telComercialEdit}
                                                mask="(11) 11111 1111"
                                                type="tel"
                                                name="telcomercial"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                maxLength="100"
                                                placeholder="E-Mail"
                                                ref={(ref) => (this.email = ref)}
                                                name="email"
                                                className="form-control"
                                                id="email"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertEmail }}
                                        >
                                            <span>{this.state.msgEmail}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                maxLength="100"
                                                placeholder="E-Mail"
                                                ref={(ref) => (this.email2 = ref)}
                                                name="email2"
                                                className="form-control"
                                                id="email2"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertEmail2 }}
                                        >
                                            <span>E-Mail inválido</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <MDBRow>
                                    <MDBCol md="8">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                placeholder="Comprador 2"
                                                ref={(ref) => (this.nomeSocio = ref)}
                                                name="nomeSocio"
                                                className="form-control"
                                                id="nomeSocio"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertNomeComprador2 }}
                                        >
                                            <span>Nome obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Dt Nasc.
                                                    </span>
                                                </div>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    ref={(ref) => (this.dtNascSocio = ref)}
                                                    onChange={this._onMaskDateBirth}
                                                    maxLength="10"
                                                    name="dtNascSocio"
                                                    placeholder="Data de nascimento"
                                                    aria-label="Data de nascimento"
                                                    aria-describedby="basic-addon"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertDtNascComprador2 }}
                                        >
                                            <span>Data de Nascimento obrigatória</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="RG Comprador 2"
                                                ref={(ref) => (this.rgSocio = ref)}
                                                maxLength="50"
                                                name="rgSocio"
                                                id="rgSocio"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="CPF/CNPJ Comprador 2"
                                                name="cpfCnpjSocio"
                                                ref={(ref) => (this.cpfCnpjSocio = ref)}
                                                onChange={this._onMaskCpfCnpj}
                                                maxLength="18"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCpfSocio }}
                                        >
                                            <span>CPF/CNPJ Comprador inválido</span>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{
                                                display: this.state.alertCpfObrigatórioComprador2,
                                            }}
                                        >
                                            <span>CPF/CNPJ obrigatório</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <Select
                                                options={this.state.estadosCivis}
                                                value={estadoCivilSocioSelecionado}
                                                onChange={this.alteraraEstadoCivilSocio}
                                                placeholder="Estado Civil Comprador"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertEstadoCivilComprador2 }}
                                        >
                                            <span>Estado civil obrigatório</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <input
                                            type="text"
                                            maxLength="200"
                                            placeholder="Profissão"
                                            ref={(ref) => (this.profissaoComprador2 = ref)}
                                            name="profissaoComprador2"
                                            className="form-control"
                                            id="profissaoComprador2"
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <MaskedInput
                                                placeholder="Celular"
                                                type="tel"
                                                className="form-control"
                                                value={this.celularEditSocio}
                                                mask="(11) 11111 1111"
                                                ref={(ref) => (this.celularSocio = ref)}
                                                name="celularSocio"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                maxLength="100"
                                                placeholder="E-Mail Sócio"
                                                ref={(ref) => (this.emailSocio = ref)}
                                                name="emailSocio"
                                                className="form-control"
                                                id="emailSocio"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <br></br>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxLength="200"
                                                placeholder="Nome conjuge do Comprador 2"
                                                className="form-control"
                                                ref={(ref) => (this.nomeConjugeSocio = ref)}
                                                name="nomeConjugeSocio"
                                                id="nomeconjugesocio"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Dt Nasc.
                                                    </span>
                                                </div>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    ref={(ref) => (this.dtNascConjugeSocio = ref)}
                                                    name="dtNascConjugeSocio"
                                                    placeholder="Data de nascimento"
                                                    aria-label="Data de nascimento"
                                                    onChange={this._onMaskDateBirth}
                                                    maxLength="10"
                                                    aria-describedby="basic-addon"
                                                />
                                            </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                ref={(ref) => (this.rgConjujgeSocio = ref)}
                                                maxLength="50"
                                                name="rgConjujgeSocio"
                                                placeholder="RG Conjuge Comprador 2"
                                                id="rgconjugesocio"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                placeholder="CPF Conjuge Comprador 2"
                                                className="form-control"
                                                ref={(ref) => (this.cpfConjugeSocio = ref)}
                                                onChange={this._onMaskCpfCnpj}
                                                maxLength="14"
                                                name="cpfConjugeSocio"
                                            />
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCpfConjugeSocio }}
                                        >
                                            <span>CPF do Conjuge do Comprador 2 inválido</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="8">
                                        <input
                                            type="text"
                                            maxLength="200"
                                            placeholder="Profissão"
                                            ref={(ref) => (this.profissaoConjugeComprador2 = ref)}
                                            name="profissaoConjugeComprador2"
                                            className="form-control"
                                            id="profissaoConjugeComprador2"
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        {this.state.visibleButtonUpdSocioComprador ? (
                                            <button
                                                type="button"
                                                className="btn btn-success btn-md"
                                                onClick={this.addComprador}
                                            >
                                                Adicionar Comprador
                                            </button>
                                        ) : null}
                                        &nbsp;&nbsp;
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        {!this.state.visibleButtonUpdSocioComprador ? (
                                            <button
                                                type="button"
                                                className="btn btn-warning btn-md"
                                                onClick={this.cancelComprador}
                                            >
                                                Cancelar
                                            </button>
                                        ) : null}
                                        {!this.state.visibleButtonUpdSocioComprador ? (
                                            <button
                                                type="button"
                                                className="btn btn-info btn-md"
                                                onClick={this.updComprador}
                                            >
                                                Atualizar Comprador
                                            </button>
                                        ) : null}
                                        &nbsp;&nbsp;
                                        {!this.state.visibleButtonUpdSocioComprador ? (
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-md"
                                                onClick={this.delComprador}
                                            >
                                                Excluir Comprador
                                            </button>
                                        ) : null}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <BootstrapTable
                                            ref={(ref) => (this.tblCompradoresRef = ref)}
                                            keyField="Id"
                                            data={this.state.compradores}
                                            selectRow={selectRowProp}
                                            columns={columnsCompradores}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardHeader className="cardHeaderColor">Condições de pagamento</MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Valor á vista.
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    placeholder="Valor á vista"
                                                    thousandSeparator="."
                                                    precision="2"
                                                    prefix="R$"
                                                    type="tel"
                                                    value={this.state.vlrVista}
                                                    onChangeEvent={this.handleChange}
                                                    name="vlrVista"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertVlrVista }}
                                        >
                                            <span>{this.state.messageVlrVista}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Valor da Entrada.
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    type="tel"
                                                    precision="2"
                                                    prefix="R$"
                                                    onBlur={this.montaParcelaEntrada}
                                                    value={this.state.vlrEntrada}
                                                    onChangeEvent={this.handleChange}
                                                    name="vlrEntrada"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertVlrEntrada }}
                                        >
                                            <span>{this.state.messageVlrEntrada}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Qtde de Entradas.
                                                    </span>
                                                </div>
                                                <input
                                                    type="Number"
                                                    onBlur={this.montaParcelaEntrada}
                                                    min="0"
                                                    max="99"
                                                    defaultValue="0"
                                                    step="1"
                                                    ref={(ref) => (this.qtdeEntrada = ref)}
                                                    name="qtdeEntrada"
                                                    className="form-control"
                                                    id="qtdeEntrada"
                                                />
                                            </div>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Taxa de juros(%).
                                                    </span>
                                                </div>
                                                <input
                                                    type="Number"
                                                    min="0"
                                                    max="99"
                                                    defaultValue="0"
                                                    step="0.01"
                                                    ref={(ref) => (this.txJuros = ref)}
                                                    name="txJuros"
                                                    className="form-control"
                                                    id="txJuros"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertTaxaJuros }}
                                        >
                                            <span>{this.state.messageTaxaJuros}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Correção Monetária.
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    ref={(ref) => (this.correcaoMonetaria = ref)}
                                                    maxLength="20"
                                                    list="indicesInflacao"
                                                    name="correcaomonetaria"
                                                    className="form-control"
                                                    id="correcaomonetaria"
                                                />
                                            </div>
                                        </div>
                                        <datalist id="indicesInflacao">
                                            <option value="IGP-M" />
                                            <option value="IPCA" />
                                            <option value="IPC" />
                                            <option value="IGP-DI" />
                                            <option value="INCC-M" />
                                        </datalist>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertCorrecaoMonetaria }}
                                        >
                                            <span>{this.state.messageCorrecaoMonetaria}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Taxa de cadastro (R$).
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    type="tel"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    prefix="R$"
                                                    value={this.state.txCadastro}
                                                    onChangeEvent={this.handleChange}
                                                    name="txCadastro"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertTaxaCadastro }}
                                        >
                                            <span>{this.state.messageTxCadastro}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="4">
                                        <div className="form-group">
                                            <Select
                                                options={this.state.formaPgtoTaxaCadastro}
                                                value={formaPgtoTaxaCadastroSelecioanda}
                                                onChange={this.alteraFormaPgtoTaxaCadastro}
                                                placeholder="Forma pgto Taxa de Cadastro"
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="6">
                                        <div className="form-group">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="tipoVendaAVista"
                                                            checked={this.state.tipoVendaAVista}
                                                            onChange={this.handleChangeRadio}
                                                        />
                                                    }
                                                    label="Venda á Vista"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="tipoVendaAPrazo"
                                                            checked={this.state.tipoVendaAPrazo}
                                                            onChange={this.handleChangeRadio}
                                                        />
                                                    }
                                                    label="Venda á Prazo"
                                                />
                                            </FormGroup>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <strong>
                                            Condição de Pagamento da Entrada <hr />
                                        </strong>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                        style={{ display: this.state.alertEntradasNaoGeradas }}
                                    >
                                        <span>{this.state.messageEntradasNaoGeradas}</span>
                                    </div>
                                    <MDBCol md="8">
                                        <BootstrapTable
                                            keyField="id"
                                            data={this.state.entradas}
                                            columns={columns}
                                            cellEdit={cellEditFactory({
                                                mode: "click",
                                                blurToSave: true,
                                            })}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardHeader className="cardHeaderColor">Prestações</MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="6" lg="6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Saldo rem. a prazo.
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    prefix="R$"
                                                    type="tel"
                                                    value={this.state.vlrRemanescente}
                                                    onChangeEvent={this.handleChange}
                                                    name="vlrRemanescente"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertVlrRemanescente }}
                                        >
                                            <span>{this.state.messageVlrRemanescente}</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Qtde de parcelas
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    aria-label="qtdeparcelas"
                                                    min="0"
                                                    max="999"
                                                    defaultValue="0"
                                                    step="1"
                                                    ref={(ref) => (this.qtdeparcelas = ref)}
                                                    name="qtdeparcelas"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertQtdeParcelas }}
                                        >
                                            <span>Quantidade de prestações obrigatória</span>
                                        </div>
                                    </MDBCol>

                                    <MDBCol sm="6" lg="6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Valor da prestação
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    type="tel"
                                                    precision="2"
                                                    prefix="R$"
                                                    value={this.state.vlrPrestacao}
                                                    onChangeEvent={this.handleChange}
                                                    name="vlrPrestacao"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertVlrPrestacao }}
                                        >
                                            <span>Valor da prestação obrigatória</span>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon">
                                                        Data 1º Prestação.
                                                    </span>
                                                </div>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    ref={(ref) => (this.dtPrimPrestacao = ref)}
                                                    name="dtPrimPrestacao"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                            style={{ display: this.state.alertPrimPrestacao }}
                                        >
                                            <span>Data 1º prestação obrigatória</span>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <div className="form-group">
                                            <textarea
                                                className="form-control"
                                                placeholder="Observações da proposta"
                                                ref={(ref) => (this.observacao = ref)}
                                                name="observacao"
                                                rows="10"
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBRow>
                            <MDBCol md="4">
                                <MDBBtn color="primary" size="lg" onClick={this.limpar}>
                                    LIMPAR
                                </MDBBtn>
                            </MDBCol>
                            <MDBCol md="6" className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-success btn-lg"
                                    disabled={this.state.enabledSalvar}
                                    onClick={this.salvarProposta}
                                >
                                    SALVAR PROPOSTA
                                </button>
                            </MDBCol>
                            <MDBCol md="2"></MDBCol>
                        </MDBRow>

                        <MDBModal isOpen={this.state.modal} toggle={this.toggle} centered>
                            <MDBModalHeader toggle={this.toggle}>CEP Não encontrado</MDBModalHeader>
                            <MDBModalBody>CEP não encontrado !!</MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toggle}>
                                    FECHAR
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalErros} toggle={this.toggleErros} centered>
                            <MDBModalHeader toggle={this.toggleErros}>Verifique a proposta</MDBModalHeader>
                            <MDBModalBody>
                                Total de&nbsp;<strong>{this.state.qtdeErros}</strong>&nbsp; erro(s) encontrado(s), por
                                favor verifique a proposta!!
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toggleErros}>
                                    FECHAR
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalConfirma} toggle={this.toogleSalvar} centered>
                            <MDBModalHeader toggle={this.toogleSalvar}>Confirmação</MDBModalHeader>
                            <MDBModalBody>
                                <strong>Deseja realmente salvar a proposta?</strong>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toogleSalvar}>
                                    FECHAR
                                </MDBBtn>
                                <MDBBtn color="primary" onClick={(e) => this.confirmaProposta(e)}>
                                    SALVAR PROPOSTA
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalInfo} toggle={this.toogleInfo} centered>
                            <MDBModalHeader toggle={this.toogleInfo}>Informação</MDBModalHeader>
                            <MDBModalBody>
                                <div dangerouslySetInnerHTML={{ __html: this.state.msgInfoApi }} />
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toogleInfo}>
                                    FECHAR
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>

                        <MDBModal isOpen={this.state.modalVerificaCpf} toggle={this.toogleVerificaCpf} centered>
                            <MDBModalHeader toggle={this.toogleVerificaCpf}>Confirmação</MDBModalHeader>
                            <MDBModalBody>{this.state.msgVerificaCpf}</MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.toogleVerificaCpf}>
                                    FECHAR
                                </MDBBtn>
                                <MDBBtn
                                    color="primary"
                                    hidden={!this.state.cpfEncontrado}
                                    onClick={(e) => this.confirmaDadosProposta(e)}
                                >
                                    CONFIRMA DADOS DA PROPOSTA
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBContainer>
                </LoadingOverlay>
            </div>
        );
    }
}

export default propostanova;
