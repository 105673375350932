import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Header from './components/header'

import Mapas from './admin/mapas'
import Propostas from './admin/propostas'
import Login from './admin/login'
import Tabelas from './admin/tabelas'
import propostanova from './admin/propostanova'

import './App.css';
import Relatorios from './admin/relatorios'

class App extends Component {
  render() {
    const optionalBool = true
    return (
      <BrowserRouter  forceRefresh={optionalBool}>
        <div className="App">
          <Header />
          <Route path='/' exact component={Mapas}  />
          <Route path='/admin/mapas' component={Mapas} />
          <Route path='/admin/tabelas' component={Tabelas} />
          <Route path='/admin/propostas' component={Propostas} />
          <Route path='/admin/login' component={Login} />
          <Route path='/admin/relatorios' component={Relatorios} />
          <Route path='/admin/nova-proposta' component={propostanova} />
          <Route path='/admin/editar-proposta' component={propostanova} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
