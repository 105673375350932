import React, { Component } from 'react'
import Select from 'react-select'
import { apiDashboard, areasAcesso } from '../api'

class ComboAreas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            areas: []
        }
        this.populaArea = this.populaArea.bind(this)

    }
    componentWillMount() {
        this.populaArea();
    }
    populaArea() {
        //let arAreas = areasAcesso();
        let arAreas = []
        let arAcesso = areasAcesso();
        apiDashboard.get('Mapa/GetAreaMapaByLocalidade?localidade=')
            .then(res => {
                const areas = res.data;
                areas.forEach(element => {
                    if (element.IdArea === 0) {
                        return
                    }
                    let area = element.IdArea;
                    let localidade = element.Localidade;
                    if (typeof arAcesso.find(n => n.IdArea  === area && 
                        n.LocalidadeSigla=== localidade) !== 'undefined') {
                        arAreas.push({
                            value: element.IdArea,
                            label: element.IdArea + ' - ' + element.NomeArea,
                            localidade:element.Localidade
                        })
                    }
                });
                this.setState({ areas: arAreas })
            }).catch(erro => {
                this.setState({ areas: [] })
            })
    }
    render() {
        return (
            <Select options={this.state.areas} placeholder="Selecione o loteamento"
                onChange={this.props.handlerSelecionaArea} value={this.props.value} />
        )
    }
}
export default ComboAreas