import React, { Component } from 'react'
import Select from 'react-select'
import { dadosCorretor } from '../utilitarios'

class ComboPrazoReserva extends Component {

    constructor(props) {
        super(props)
        this.state = {
            prazo: []
        }

    }
    componentWillMount() {
        let corretor = dadosCorretor();
        this.setState({
            prazo: [{ value: 3, label: '72 Horas' },
            { value: 2, label: '48 Horas' },
            { value: 1, label: '24 Horas' }]
        })
        if (corretor != null) {
            if (corretor.gerenteCorretor == "True") {
                this.setState({
                    prazo: [{ value: 3, label: '72 Horas' },
                    { value: 2, label: '48 Horas' },
                    { value: 1, label: '24 Horas' },
                    { value: 18262, label: 'Indeterminado' },]
                })
            }
        }
    }

    render() {
        return (
            <Select options={this.state.prazo} placeholder="Prazo"
                onChange={this.props.handlerSelecionaPrazoReserva} value={this.props.value} />
        )
    }
}
export default ComboPrazoReserva