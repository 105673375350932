
/* eslint react/no-did-update-set-state: off */
import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay';
import { apiDashboard, apiDashboardMapa, apiDashboardDowloadCuritiba, apiDashboardDowloadGuarapuava, apiDashboardDowloadCamboriu } from '../api'
import { Checkbox } from 'semantic-ui-react'
import {
    MDBBtn, MDBModal, MDBModalBody, MDBModalHeader,
    MDBModalFooter, MDBRow, MDBCol,MDBContainer
} from "mdbreact";
import { saveAs } from 'file-saver'
import ComboAreas from '../components/comboareas';
import { dadosCorretor } from '../utilitarios'
import MaskedInput from 'react-maskedinput'
import * as EmailValidator from 'email-validator';
import ComboPrazoReserva from '../components/comboPrazoReserva';

let canvas = null
let context = null
let dataLotes = []
let dataSelected = []
let targ = null
let offsetX = 0
let offsetY = 0
let coordX = 0
let coordY = 0
let drag = false;

let pushItemToX = function (data, dataItemX, i) {
    let itemDel = data.find(f => f.x === i)
    if (itemDel !== undefined) {
        dataItemX.push(itemDel)
    }
}
let pushItemToY = function (data, a) {
    let itemDel = data.find(f => f.y === a)
    if (itemDel !== undefined) {
        dataSelected.push(itemDel)
    }
}


/**arDados = Array de dados a ser iterado
 * cor =  cor da marcacao, vermelho=vendido, verda=vago, amarelo=reservado
 * iterar = Indica se deve realizar a marcacao, caso o checkbox seja falso
 */
var drawPoint = function (arDados, cor, iterar, vendido, callback) {

    if (!iterar) {
        callback(null, 'ok')
        return
    }
    arDados.forEach(element => {
        var obj = {
            codigo: element.Codigo,
            complemento: element.Complemento,
            x: element.PosicaoLeft,
            y: element.PosicaoTop,
            amount: 5.5,
            color: cor,
            quadra: element.Quadra,
            lote: element.Lote,
            area: element.MetroQuadrado,
            matricula: element.Matricula,
            reservado: element.Reservado, // quando há proposta 
            vago: element.Vago,
            visita: element.Visita //visita é uma reserva
        };
        if (!vendido) {
            dataLotes.push(obj);
        }

        drawDot(obj);
    });
    callback(null, 'ok')
}
function drawDot(data) {
    if (data.x > 0 && data.y > 0) {
        //insere na memoria os lotes criados
        context.beginPath();
        context.arc(data.x, data.y, data.amount, 0, 2 * Math.PI, false);
        context.fillStyle = data.color;
        context.fill();
        context.lineWidth = 0;
        context.strokeStyle = data.color;
        context.stroke();
    }
}
class Mapas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAuth: false,
            isLoading: false,
            areas: [],
            areaSelecionada: null,
            imageSrc: null,
            mapSelectedApi: null,
            largura: 0,
            altura: 0,
            vendidosIsChecked: true,
            vagosIsChecked: true,
            reservadosIsChecked: true,
            visitaIsChecked: true,
            modalLote: false,
            lote: undefined,
            quadra: undefined,
            matricula: undefined,
            indfiscal: undefined,
            metroquadrado: undefined,
            area: null,
            loteamento: null,
            modalInfo: null,
            msgInfo: null,
            localidade: '',
            modalConfirmaVisita: false,
            modalConfirmaDesmarcacaoVisita: false,
            alertCelular: 'none',
            alertEmail: 'none',
            alertTitular: 'none',
            alertPrazo: 'none',
            prazoSelecionado: null
        }
        this.toggleChangeVendidos = this.toggleChangeVendidos.bind(this)
        this.toggleChangeVagos = this.toggleChangeVagos.bind(this)
        this.toggleChangeReservados = this.toggleChangeReservados.bind(this)
        this.toggleChangeVisita = this.toggleChangeVisita.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.toogleVisita = this.toogleVisita.bind(this)
        this.toggleModalInfo = this.toggleModalInfo.bind(this)
        this.clickCanvas = this.clickCanvas.bind(this)
        this.clickDowloadTabela = this.clickDowloadTabela.bind(this)
        this.dowloadTabela = this.dowloadTabela.bind(this)
        this.novaProposta = this.novaProposta.bind(this)
        this.confirmaVisitaLote = this.confirmaVisitaLote.bind(this)
        this.confirmaDesmacarVisitaLote = this.confirmaDesmacarVisitaLote.bind(this)
        this.stopDrag = this.stopDrag.bind(this)
        this.startDrag = this.startDrag.bind(this)
        this.quadra = null
        this.lote = null
    }
    toggleModal = () => {
        this.setState({
            modalLote: !this.state.modalLote
        });
    }
    toogleVisita = () => {
        this.setState({
            modalConfirmaVisita: !this.state.modalConfirmaVisita,
            alertCelular: 'none',
            alertEmail: 'none',
            alertTitular: 'none'
        });
    }
    toogleDesmarcarVisita = () => {
        this.setState({
            modalConfirmaDesmarcacaoVisita: !this.state.modalConfirmaDesmarcacaoVisita
        });
    }
    IsEmail(email) {
        return EmailValidator.validate(email)
    }
    toggleModalInfo = () => {
        this.setState({
            modalInfo: !this.state.modalInfo
        });
    }
    startDrag(e) {
        // determine event object

        if (!e) {
            e = window.event;
        }
        if (e.preventDefault) e.preventDefault();

        // IE uses srcElement, others use target
        targ = e.target ? e.target : e.srcElement;

        //if (targ.className !== 'dragme') { return };
        // calculate event X, Y coordinates
        offsetX = e.clientX;
        offsetY = e.clientY;

        // assign default values for top and left properties
        if (!targ.style.left) { targ.style.left = '0px' };
        if (!targ.style.top) { targ.style.top = '0px' };

        // calculate integer values for top and left 
        // properties
        coordX = parseInt(targ.style.left);
        coordY = parseInt(targ.style.top);
        drag = true;

        // move div element
        document.onmousemove = this.dragDiv;
        return false;
    }
    dragDiv(e) {
        if (!drag) { return };
        if (!e) { e = window.event };
        // var targ=e.target?e.target:e.srcElement;
        // move div element
        targ.style.left = coordX + e.clientX - offsetX + 'px';
        targ.style.top = coordY + e.clientY - offsetY + 'px';
        return false;
    }
    stopDrag() {
        drag = false;
    }
    clickCanvas() {
        if (dataSelected.length > 0) {
            let lote = dataSelected[0]
            this.setState({
                lote: lote.lote,
                quadra: lote.quadra,
                metroquadrado: lote.area == null ? undefined : lote.area,
                matricula: lote.matricula == null ? undefined : lote.matricula,
                reservado: lote.reservado, //quando há proposta
                vago: lote.vago,
                visita: lote.visita, // reserva
                Codigo: lote.codigo,
                Complemento: lote.complemento,
            })
            this.setState({ modalLote: true })
        }
    }
    componentWillMount() {
        let storage = localStorage.getItem('corretorapp');
        if (storage !== null) {
            this.setState({ isAuth: true })
        }
    }
    novaProposta() {
        //  TODO - APAGAR DEV
        // const {imageSrc, ...others} = this.state;
        // console.log(JSON.stringify(others));
        //  TODO - APAGAR DEV
        this.props.history.push({
            pathname: "/admin/nova-proposta", state:
            {
                tipo: 'nova',
                Codigo: this.state.Codigo,
                Complemento: this.state.Complemento,
                quadra: this.state.quadra,
                lote: this.state.lote,
                idarea: this.state.areaSelecionada.value,
                localidade: this.state.areaSelecionada.localidade
            }
        });
    }

    confirmaDesmacarVisitaLote() {
        this.toggleModal();
        this.toogleDesmarcarVisita();
        if (this.state.areaSelecionada != null) {
            let corretor = dadosCorretor();
            let objProposta = {
                Localidade: this.state.areaSelecionada.localidade,
                Codigo: this.state.Codigo,
                Complemento: this.state.Complemento,
                Lote: this.state.lote,
                Quadra: this.state.quadra,
                Area: this.state.areaSelecionada.value,
                Corretor: corretor != null ? corretor.id : 0,
                Gerente: corretor.gerenteCorretor
            }
            apiDashboard.post('/Corretores/DesmarcarReserva', objProposta)
                .then(response => {
                    this.setState({ modalInfo: true, msgInfo: response.data.MensagemRetorno })
                    this.setState({ isLoading: false })
                    this.selecionaArea(this.state.areaSelecionada)
                })
                .catch(erro => {
                    this.setState({ msgInfoApi: erro, msgInfo: true })
                    this.setState({ isLoading: false })
                })
        }
    }
    confirmaVisitaLote() {

        //validacao
        let countErro = 0
        //valida titular
        this.setState({ alertTitular: 'none' })
        if (this.titular.value === undefined || this.titular.value.trim().length === 0) {
            this.setState({ alertTitular: 'block' })
            countErro++
        }
        this.setState({ alertCelular: 'none' })
        if (this.celular.input.value == null || this.celular.input.value.trim().length === 0) {
            this.setState({ alertCelular: 'block' })
            countErro++
        }
        this.setState({ alertEmail: 'none' })
        if (this.email.value.trim().length > 0) {
            if (!this.IsEmail(this.email.value)) {
                this.setState({ alertEmail: 'block', msgEmail: 'E-mail inválido' })
                countErro++
            }
        }
        this.setState({ alertPrazo: 'none' })

        if (this.state.prazoSelecionado == null) {
            this.setState({ alertPrazo: 'block' })
            countErro++;
        }

        if (countErro > 0) {
            return;
        }

        this.toggleModal();
        this.toogleVisita();
        if (this.state.areaSelecionada != null) {
            let corretor = dadosCorretor();
            let objProposta = {
                Localidade: this.state.areaSelecionada.localidade,
                Codigo: this.state.Codigo,
                Complemento: this.state.Complemento,
                Lote: this.state.lote,
                Quadra: this.state.quadra,
                Area: this.state.areaSelecionada.value,
                Corretor: corretor != null ? corretor.id : 0,
                Nome: this.titular.value,
                Email: this.email.value,
                Celular: this.celular.input.value,
                PrazoReserva: this.state.prazoSelecionado.value,
                Gerente: corretor.gerenteCorretor
            }
            apiDashboard.post('/Corretores/ReservaLote', objProposta)
                .then(response => {
                    this.setState({ modalInfo: true, msgInfo: response.data.MensagemRetorno })
                    this.setState({ isLoading: false })
                    this.selecionaArea(this.state.areaSelecionada)
                })
                .catch(erro => {
                    this.setState({ msgInfoApi: erro, msgInfo: true })
                    this.setState({ isLoading: false })
                })
        }
    }
    componentDidUpdate() {
        canvas = this.refs.canvas
        context = canvas.getContext("2d")
        //QUANDO CLICAR NA MARCACAO
        canvas.onclick = function (eventObject) {
            var offsetxMin = eventObject.offsetX - 20;
            var offsetxMax = eventObject.offsetX + 20;
            dataSelected = []
            var offsetyMin = eventObject.offsetY - 10;
            var offsetyMax = eventObject.offsetY + 10;
            let dataItemX = []
            dataSelected = dataLotes.filter((lote) => {
                return (lote.x >= offsetxMin && lote.x <= offsetxMax) && (lote.y >= offsetyMin && lote.y <= offsetyMax)
            })
        }
    }

    toggleChangeVendidos(e) {
        this.setState({ vendidosIsChecked: !this.state.vendidosIsChecked })
        this.selecionaArea(this.state.areaSelecionada)
    }
    toggleChangeVagos(e) {
        this.setState({ vagosIsChecked: !this.state.vagosIsChecked })
        this.selecionaArea(this.state.areaSelecionada)
    }
    toggleChangeReservados(e) {
        this.setState({ reservadosIsChecked: !this.state.reservadosIsChecked })
        this.selecionaArea(this.state.areaSelecionada)
    }
    toggleChangeVisita(e) {
        this.setState({ visitaIsChecked: !this.state.visitaIsChecked })
        this.selecionaArea(this.state.areaSelecionada)
    }
    clickDowloadTabela() {
        if (this.state.areaSelecionada != null) {
            this.dowloadTabela(this.state.areaSelecionada.value, this.state.areaSelecionada.localidade)
        }
    }
    dowloadTabela(idArea, localidade) {

        if (localidade === 'Cb') {
            apiDashboardDowloadCamboriu.get(`Corretores/DownloadTabelaByLoteamento?idArea=${idArea}&localidade=${localidade}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        } else if (localidade === 'Cwb') {
            apiDashboardDowloadCuritiba.get(`Corretores/DownloadTabelaByLoteamento?idArea=${idArea}&localidade=${localidade}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        }
        else if (localidade === 'Gua') {
            apiDashboardDowloadGuarapuava.get(`Corretores/DownloadTabelaByLoteamento?idArea=${idArea}&localidade=${localidade}`)
                .then(function (response, status, xhr) {
                    let myHeaders = response.headers;
                    let contentDisposition = myHeaders["content-disposition"]
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = filename.replace('"', '');
                    filename = filename.replace('"', '');
                    var fileStream = response.data;
                    var blob = new Blob([fileStream], {
                        //type: "image/png"
                    });
                    saveAs(blob, filename)

                }).catch(erro => {
                    this.setState({ modalLote: false, modalInfo: true, msgInfo: "Não há tabelas disponíveis." })
                })
        }
    }
    // ao trocar seleção de area
    selecionaArea = (areaSelecionada) => {
        this.setState({ areaSelecionada });

        this.buscaMapaArea(areaSelecionada)
        this.setState({ loteamento: areaSelecionada.label })
        dataLotes = []
    }
    selecionaPrazoReserva = (prazoSelecionado) => {
        this.setState({ prazoSelecionado });
    }

    drawImageMap(mapa) {
        let vendidos = this.state.vendidosIsChecked
        let vagos = this.state.vagosIsChecked
        let reservados = this.state.reservadosIsChecked //quando há proposta
        let visita = this.state.visitaIsChecked //reserva

        var altura = mapa.Heigth;
        var largura = mapa.Width;
        var img1 = new Image();
        img1.src = this.state.imageSrc;
        img1.onload = function () {
            context.drawImage(img1, 0, 0);
            //busca dados dos mapas do loteamento
            apiDashboard.get(`Mapa/GetImoveisAreaFilter?codArea=${mapa.IdArea}&localidade=${mapa.Localidade}&codigo=&complemento=&quadra=&lote=`)
                .then(res => {
                    var cor = ""
                    if (res.data.length > 0) {
                        var arVendidos = res.data.filter(r => r.Vago === false)

                        cor = '#D9534F'; // Vendido - Vermelho
                        drawPoint(arVendidos.length > 0 ? arVendidos : [], cor, vendidos, true, function (err, resp) {
                            var arVagos = res.data.filter(r => r.Vago === true )
                            cor = '#2e613b'; // Vago - Verde
                            drawPoint(arVagos.length > 0 ? arVagos : [], cor, vagos, false, function (err, resp) {
                                var arReservados = res.data.filter(r => r.Reservado === true && r.Vago == true )
                                cor = '#f2db09'; // Aguardando - Amarelo
                                drawPoint(arReservados.length > 0 ? arReservados : [], cor, reservados, false, function (err, resp) {
                                    var arVisita = res.data.filter(r => r.Visita === true && r.Reservado === false && r.Vago === true)
                                    cor = '#0915f2'; // Reservado - Azul
                                    drawPoint(arVisita.length > 0 ? arVisita : [], cor, visita, false, function (err, resp) {

                                    })

                                })
                            })
                        })

                    }
                }).catch(erro => {
                    console.error(erro)
                })
        }
        this.setState({ largura: largura, altura: altura })
    }

    async buscaMapaArea(areaSelecionada) {
        this.setState({ isLoading: true })
        this.setState({ imageSrc: null })
        apiDashboard.get(`Mapa/GetAreaMapaByLocalidadeAndCodigo?codigoArea=${areaSelecionada.value}&localidade=${areaSelecionada.localidade}`)
            .then(res => {
                if (res.data) {
                    var pars = res.data.ImageName.split('.')
                    apiDashboardMapa.get(`Mapa/GetImageMap?ext=${pars[1]}&fileName=${pars[0]}`)
                        .then(resMapa => {
                            let strBase64 = 'data:image/png;base64,' + this.arrayBufferToBase64(resMapa.data);
                            this.setState({ imageSrc: strBase64 })
                            this.drawImageMap(res.data)
                        }).catch(erroMapa => {
                            this.setState({ isLoading: false })
                        })
                }
                this.setState({ isLoading: false })
            }).catch(erro => {
                this.setState({ isLoading: false })
            })
    }

    arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    render() {
        if (!this.state.isAuth) {
            this.props.history.push("/admin/login");
            document.location.reload(true);
        }
        return (
            <div>
                <MDBContainer fluid>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text='Aguarde...' >
                        <MDBRow>
                            <MDBCol sm="12" lg="6" className="text-left topArea">
                                <ComboAreas handlerSelecionaArea={this.selecionaArea} />
                            </MDBCol>
                            <MDBCol sm="12" lg="6" className="topArea">
                                <Checkbox className="chkVendidos" name="vendidosIsChecked" onChange={this.toggleChangeVendidos} checked={this.state.vendidosIsChecked} />  <span > Vendidos &nbsp; &nbsp; </span>
                                <Checkbox className="chkVagos" name="vagosIsChecked" onChange={this.toggleChangeVagos} checked={this.state.vagosIsChecked} /> <span>  Vagos &nbsp; &nbsp; </span>
                                <Checkbox className="chkReservados" name="reservadosIsChecked" onChange={this.toggleChangeReservados} checked={this.state.reservadosIsChecked} />  <span>  Aguardando Documentação &nbsp; &nbsp; </span>
                                <Checkbox className="chkVisita" name="visitaIsChecked" onChange={this.toggleChangeVisita} checked={this.state.visitaIsChecked} />  <span>  Reservados &nbsp; &nbsp; </span>
                            </MDBCol>

                        </MDBRow>
                        <br></br>
                        <div className="row">
                            <canvas ref="canvas" width={this.state.largura} className="dragme"
                                onClick={this.clickCanvas} height={this.state.altura}
                                onMouseUp={this.stopDrag} onMouseDown={this.startDrag} />
                        </div>
                    </LoadingOverlay>
                </MDBContainer>
                <MDBModal isOpen={this.state.modalLote} toggle={this.toggleModal} size="lg" centered>
                    <MDBModalHeader toggle={this.toggleModal}>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol md="12">
                                <h2>{this.state.loteamento} </h2>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12">
                                <hr />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="3">
                                <div className="form-group">
                                    <label htmlFor="quadra"><b>Quadra</b></label>
                                    <input
                                        type="text"
                                        className="form-control" disabled name="quadra" value={this.state.quadra}
                                        id="formGroupExampleInput" />
                                </div>
                            </MDBCol>
                            <MDBCol md="3">
                                <div className="form-group">
                                    <label htmlFor="lote">  <b>Lote</b></label>
                                    <input
                                        type="text" name="lote" disabled
                                        className="form-control" value={this.state.lote}
                                        id="lote" />
                                </div>
                            </MDBCol>
                            <MDBCol md="3">
                                <div className="form-group">
                                    <label htmlFor="metros">  <b>Área&nbsp;M²</b></label>
                                    <input value={this.state.metroquadrado} disabled
                                        className="form-control"
                                        id="metros" />
                                </div>
                            </MDBCol>
                            <MDBCol md="3">
                                <div className="form-group">
                                    <label htmlFor="matricula"><b>Matrícula</b></label>
                                    <input
                                        type="text" disabled value={this.state.matricula}
                                        className="form-control"
                                        id="matricula" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleModal}>Fechar</MDBBtn>
                        <MDBBtn color="primary" onClick={this.clickDowloadTabela}>Baixar tabela</MDBBtn>
                        {(!this.state.reservado && !this.state.visita) && <MDBBtn color="btn btn-warning" onClick={this.toogleVisita}>Reservar</MDBBtn>}
                        {(!this.state.reservado && this.state.visita) && <MDBBtn color="btn btn-warning" onClick={this.toogleDesmarcarVisita}>Desmarcar Reserva</MDBBtn>}
                        {(!this.state.reservado && !this.state.visita) && <MDBBtn color="btn btn-success" onClick={this.novaProposta}>Nova proposta</MDBBtn>}
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modalConfirmaVisita} toggle={this.toogleVisita} centered>
                    <MDBModalHeader toggle={this.toogleVisita}>Dados da reserva</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol sm="12" lg="8">
                                <div className="form-group">
                                    <input
                                        type="text" maxLength="200"
                                        className="form-control" placeholder="Titular"
                                        ref={(ref) => this.titular = ref} name="titular"
                                        id="nome" />
                                </div>
                                <div className="alert alert-danger" role="alert"
                                    style={{ display: this.state.alertTitular }}>
                                    <span>Nome é obrigatório.</span>
                                </div>
                            </MDBCol>
                            <MDBCol sm="6" lg="4">
                                <div className="form-group">
                                    <MaskedInput
                                        placeholder="Celular"
                                        type="tel"
                                        className="form-control" value={this.celularEdit}
                                        mask="(11) 11111 1111" ref={(ref) => this.celular = ref}
                                        name="celular" />
                                </div>
                                <div className="alert alert-danger" role="alert" style={{ display: this.state.alertCelular }}>
                                    <span>Celular é obrigatório</span>
                                </div>
                            </MDBCol>
                            <MDBCol lg="8" sm="12">
                                <div className="form-group">
                                    <input
                                        type="email" maxLength="100" placeholder="E-Mail" ref={(ref) => this.email = ref}
                                        name="email"
                                        className="form-control"
                                        id="email" />
                                </div>
                                <div className="alert alert-danger" role="alert" style={{ display: this.state.alertEmail }}>
                                    <span>{this.state.msgEmail}</span>
                                </div>
                            </MDBCol>
                            <MDBCol lg="4" sm="12">
                                <ComboPrazoReserva handlerSelecionaPrazoReserva={this.selecionaPrazoReserva} />
                                <div className="alert alert-danger" role="alert" style={{ display: this.state.alertPrazo }}>
                                    <span>Selecione o prazo de reserva</span>
                                </div>
                            </MDBCol>
                        </MDBRow>

                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toogleVisita}>FECHAR</MDBBtn>
                        <MDBBtn color="primary" onClick={this.confirmaVisitaLote} >RESERVAR LOTE</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modalConfirmaDesmarcacaoVisita} toggle={this.toogleDesmarcarVisita} centered>
                    <MDBModalHeader toggle={this.toogleDesmarcarVisita}>Confirmação</MDBModalHeader>
                    <MDBModalBody>
                        <strong>Deseja realmente desmarcar Reserva do Lote ?</strong>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toogleDesmarcarVisita}>FECHAR</MDBBtn>
                        <MDBBtn color="primary" onClick={this.confirmaDesmacarVisitaLote} >DESMARCAR RESERVA</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.modalInfo} toggle={this.toggleModalInfo} size="md" centered>
                    <MDBModalHeader toggle={this.toggleModalInfo}>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol md="12">
                                <h3>{this.state.msgInfo} </h3>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.toggleModalInfo}>Fechar</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}
export default Mapas